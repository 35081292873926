import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    socket: null,
}

const shipsSocket = createSlice({
    name: 'shipsSocket',
    initialState,
    reducers: {
        setSocket: (state, action) => {
            state.socket = action.payload;
        },
    },

    extraReducers(builder) {

    }
})

export const {
    setSocket,
} = shipsSocket.actions;

export const getSocket = (state) => state.shipsSocket.socket;

export default shipsSocket.reducer
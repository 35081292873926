import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from '../api';
import InputBox from "../components/base/InputBox";
import PanelButton from "../components/base/PanelButton";
import { useClickOutside } from '../helpers/useClickOutside';
import { fetchCreateMission } from "../store/thunks/fleetThunks";

const ConfigHeader = ({ onClose, type }) => (
    <div className="flex gap-5 justify-between px-2 py-1 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:text-white dark:bg-[#151515]">
        <div>Create Mission</div>
        <button onClick={onClose}>
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddcb33ebb0cb0d777eaebba2ca6701637f967354c4f2b41114a225bd26c5a850?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
                className="shrink-0 my-auto w-3 aspect-square close-icon"
            />
        </button>
    </div>
);

export const CreateNewMissionModal = ({ onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        // fleet: '',
        vessel: '',
        // imei: '',
        // serial: '',
        description: '',
        start: '',
        end: '',
    });

    console.log('formData:', formData);

    const [formErrors, setFormErrors] = useState({})

    const [allVessels, setAllVessel] = useState([]);
    const [vesselsInputValue, setVesselsInputValue] = useState("");
    const [vesselsDropdownOpen, setVesselsDropdownOpen] = useState(false);

    const dropdownRef = useRef();
    useClickOutside(dropdownRef, () => setVesselsDropdownOpen(false));

    const handleChange = (name, val) => {
        setFormData(prevState => ({ ...prevState, [name]: val }))
        setFormErrors(prev => ({ ...prev, [name]: false }))
    }

    const requieredFields = ['description']
    const submit = async () => {
        const fields = Object.entries(formData)
        let anyErrors = false
        fields.forEach(field => {
            console.log(field)
            if (requieredFields.includes(field[0]) && !field[1]) {
                setFormErrors(prev => ({ ...prev, [field[0]]: true }))
                anyErrors = true
            }
        })
        // if((formData.start && !formData.end) || (!formData.start && formData.end)) {
        //     anyErrors = true
        //     setFormErrors(prev => ({...prev, start: true, end: true}))
        // }
        if (anyErrors) return


        setIsLoading(true)

        // const createFleet = await dispatch(fetchCreateFleet({title: formData.fleet}))
        // const fleetId = createFleet?.payload?.id || null

        // let vesselId = null

        // if (fleetId) {
        //     const createVessel = await dispatch(fetchCreateVessel({
        //         "imei": formData.imei,
        //         "name": formData.vessel,
        //         "serial": formData.serial,
        //         "fleetId": fleetId
        //     }))

        //     console.log(createVessel, 'createVessel')

        //     vesselId = createVessel?.payload?.id || null
        // }

        // console.log(vesselId)
        console.log("formData:", formData)


        const createMission = await dispatch(fetchCreateMission({
            "description": formData.description,
            ...formData.start ? { "start": formData.start } : {},
            // "start": formData.start,
            ...formData.end ? { "end": formData.end } : {},
            // "end": formData.end,
            // "status": formData.vessel && formData.start && formData.end ? "draft" : "blueprint",
            "status": formData.vessel && formData.start && formData.end ? "draft" : "draft",
            ...formData.vessel ? { "vesselId": formData.vessel } : {},
            // "vesselId": formData.vessel
        }))

        console.log('createMission:', createMission)

        if (createMission?.payload?.id) {
            navigate(`/mission-planner/map/${createMission?.payload?.id}`);
        }
      

        setIsLoading(false)

        onClose && onClose()


    }

    const getAllFleets = async () => {
        let res = await api.fleet.getAllVessel();
        setAllVessel(res.data);
    }

    useEffect(() => {
        getAllFleets();
    }, []);

    useEffect(() => {
        if (allVessels && formData.vessel) {
            setVesselsInputValue(allVessels.find(item => +item.id === +formData.vessel)?.name);
        }
    }, [allVessels, formData]);

    return (
        <div style={{
            position: 'fixed',
            width: 362,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }}
            className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 max-w-[336px] border border-solid border-neutral-950 border-opacity-30 dark:border-[#ffffff1a] dark:bg-[#1b1b1b]">
            <ConfigHeader onClose={onClose} />

            <div className="flex flex-col justify-end px-3 pb-3 w-full font-semibold text-neutral-950">

                <div className="mt-[12px] mb-[8px]">Mission Description</div>
                <InputBox
                    type='text'
                    placeholder='Description'
                    value={formData.description}
                    wrapperClassName={`${formErrors['description'] ? 'input-error' : ''} `}
                    disabled={isLoading}
                    onChange={(e) => handleChange('description', e.target.value)}
                />

                {/*wrapperClassName={`${errors['password'] ? 'input-error' : ''} `}*/}
                {/* <div className="mt-[12px] mb-[8px]">Fleet Name</div>
                <InputBox
                    type='text'
                    placeholder='Name'
                    value={formData.fleet}
                    wrapperClassName={`${formErrors['fleet'] ? 'input-error' : ''} `}
                    disabled={isLoading}
                    onChange={(e) => handleChange('fleet', e.target.value)}
                /> */}

                <div className="mt-[12px] mb-[8px]">Vessel Name</div>
                <div className="relative" ref={dropdownRef}>
                    <InputBox
                        type='text'
                        placeholder='Name'
                        wrapperClassName={`${formErrors['vessel'] ? 'input-error' : ''} `}
                        disabled={isLoading}
                        value={vesselsInputValue}
                        onInput={(e) => { setVesselsInputValue(e.target.value); }}
                        onFocus={() => { setVesselsDropdownOpen(true); }}
                    />
                    {vesselsDropdownOpen ?
                        <ul className="absolute right-[-1px] top-[calc(100%+2px)] rounded-[2px] border border-[#0B0B0B1A] bg-[#EFF2F4] min-w-[calc(100%+2px)] z-10 max-h-[180px] overflow-y-auto">
                            {
                                allVessels?.filter(itemF => itemF.name?.toLowerCase().includes(vesselsInputValue?.toLowerCase()))?.map((item, index) => {
                                    const isBusy = item.isHaveActiveMission
                                    return <li key={index}>
                                        <button
                                            className="block py-[5px] px-[10px] hover:text-[#E42320]"
                                            onClick={(e) => {
                                                // selectVessel(item.id);
                                                handleChange('vessel', item.id)
                                                setVesselsDropdownOpen(false);
                                            }}
                                        ><span>{item.name}</span> {isBusy && <span className="bg-[#1c55c340] inline-flex rounded-[4px] items-center px-1 h-4 text-[#1C55C3">Active</span>}</button>
                                    </li>
                                })
                            }
                        </ul> : null}
                </div>

                {/* <div className="mt-[12px] mb-[8px]">Vessel imei</div>
                <InputBox
                    type='text'
                    placeholder='imei'
                    value={formData.imei}
                    wrapperClassName={`${formErrors['imei'] ? 'input-error' : ''} `}
                    disabled={isLoading}
                    onChange={(e) => handleChange('imei', e.target.value)}
                /> */}

                {/* <div className="mt-[12px] mb-[8px]">Vessel Serial</div>
                <InputBox
                    type='text'
                    placeholder='Serial'
                    value={formData.serial}
                    wrapperClassName={`${formErrors['serial'] ? 'input-error' : ''} `}
                    disabled={isLoading}
                    onChange={(e) => handleChange('serial', e.target.value)}
                /> */}


                {/* <div className="">
                    <div className="font-semibold text-neutral-950 mt-[12px] mb-[8px] dark:text-white">Start Date</div>
                    <div className="relative">
                        <DatePicker
                            className={`map-side-input map-side-input-picker ${formErrors['start'] ? 'picker-error' : ''}`}
                            selected={formData.start}
                            onChange={(e) => handleChange('start', e)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            portalId="datepicker-portal12"

                        />
                        <svg className="map-side-filter-input-svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 19H5V8H19M16 1V3H8V1H6V3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H18V1M17 12H12V17H17V12Z"
                                fill="#0B0B0B" fillOpacity="0.5"/>
                        </svg>


                    </div>
                </div> */}

                {/* <div className="">
                    <div className="font-semibold text-neutral-950 mt-[12px] mb-[8px] dark:text-white">End Date</div>
                    <div className="relative">
                        <DatePicker
                            className={`map-side-input map-side-input-picker ${formErrors['end'] ? 'picker-error' : ''}`}
                            selected={formData.end}
                            onChange={(e) => handleChange('end', e)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            portalId="datepicker-portal13"

                        />
                        <svg className="map-side-filter-input-svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 19H5V8H19M16 1V3H8V1H6V3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H18V1M17 12H12V17H17V12Z"
                                fill="#0B0B0B" fillOpacity="0.5"/>
                        </svg>


                    </div>
                </div> */}

                <PanelButton
                    className='w-fit ml-auto mt-[12px]'
                    onClick={submit}
                    disabled={isLoading}
                >
                    Create
                </PanelButton>


            </div>


        </div>
    )
}
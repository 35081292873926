import React from 'react';
import {createBrowserRouter, createRoutesFromElements, Route, Outlet} from "react-router-dom";

import {TestPage} from '../pages/testpage';
import {MapView} from '../pages/MapView';
import {Dashboard} from '../pages/Dashboard/Dashboard';
import {ManageConfigurations} from '../pages/Dashboard/ManageConfigurations';
import {DataExplorer} from '../pages/DataExplorer/DataExplorer';
import {DataExplorerMission} from '../pages/DataExplorer/DataExplorerMission';
import {ManageMissions} from '../pages/MissionPlanner/ManageMissions';
import {Account} from '../pages/Account/Account';
import SidebarLayout from '../layouts/SidebarLayout';
import MissionPlanner from '../pages/MissionPlanner/MissionPlanner';
import ResetPassword from '../pages/ResetPassword';
import {MapWindyContainer} from "../pages/MissionPlanner/MapWindyContainer";
import ProtectedRouteLayout from '../layouts/ProtectedRouteLayout';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            {/* <Route path="/" element={<TestPage />}/>  */}


            {/* <Route path="/dashboard" element={<SidebarLayout />}>
				<Route path='/dashboard/' element={<Dashboard />} />
				<Route path='/dashboard/manage-configurations' element={<ManageConfigurations />} />
			</Route> */}
            <Route element={<ProtectedRouteLayout/>}>
                <Route path="/map-view" element={<MapView/>}/>
                <Route path="/" element={<SidebarLayout/>}>
                    <Route path='/' element={<Dashboard/>}/>
                    <Route path='/manage-configurations' element={<ManageConfigurations/>}/>
                </Route>

                <Route path="/data-explorer" element={<SidebarLayout/>}>
                    <Route path="/data-explorer/" element={<DataExplorer/>}/>
                    <Route path="/data-explorer/result" element={<DataExplorerMission/>}/>
                </Route>


                <Route path="/mission-planner" element={<SidebarLayout/>}>
                    <Route path="/mission-planner" element={<ManageMissions/>}/>
                    <Route path="/mission-planner/wave" element={<TestPage/>}/>
                    <Route path="/mission-planner/:missionID" element={<TestPage/>}/>

                    {/*<Route path="/mission-planner/manage-missions" element={<ManageMissions />} />	*/}
                </Route>

                <Route path="/mission-planner/map/:missionId" element={<MapWindyContainer/>}/>

                <Route path="/account" element={<SidebarLayout/>}>
                    <Route path="/account/" element={<Account/>}/>
                </Route>
                <Route path="/reset-password" element={<ResetPassword/>}>

                </Route>
            </Route>
        </>
    ), {basename: "/"}
)

export default router

import { useState } from "react"
import { LocationDataModal } from "../Modals/LocationDataModal"
import { SelectAMissionModal } from "../Modals/SelectAMissionModal"
import MapComponent from "../components/MapComponent"
import { MapSideFilter } from "../components/MapSideFilter"
import { MapSideMenu } from "../components/MapSideMenu"

export const MapView = () => {

    const [modalData, setModalData] = useState({
        name: ''
    })


    const pointClickCallback = () => {
        setModalData({name: 'location'})
    }


    return (
        <div className="h-screen">

            <div className="fixed top-[10px] left-[10px] z-10">
                <MapSideMenu />
            </div>

            {/*<div className="fixed top-[10px] right-[10px] z-10">*/}
            {/*    <MapSideFilter />*/}
            {/*</div>*/}

            {modalData.name === 'location' && <LocationDataModal close={() => setModalData({name: ''})} openMissionModal={() => setModalData({name: 'mission'})} />}
            {modalData.name === 'mission' && <SelectAMissionModal close={() => setModalData({name: 'location'})} />}


            <MapComponent pointClickCallback={pointClickCallback} />
        </div>
    )
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchWidgetsData = createAsyncThunk(
    '/get-all-widgets/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.dashboard.getAllWidgets(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    }
);
export const fetchWidgetById = createAsyncThunk(
    '/get-widget-by-id/',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.dashboard.getWidgetById(id);
            // if (res.success) {
                return res.data;
            // }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    }
);

export const fetchLayoutsData = createAsyncThunk(
    '/get-all-layouts/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.dashboard.getAllLayouts(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    }
);
export const fetchLayoutById = createAsyncThunk(
    '/get-layout-by-id/',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.dashboard.getLayoutById(id);
            // if (res.success) {
                return res.data;
            // }
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    }
);

import React, { Children, useEffect, useState } from 'react';
import { ReactComponent as XIcon } from "../assets/img/icons/x.svg";
import InputBox from '../components/base/InputBox';

const ConfirmButton = ({ onClick }) => (
    <button
        className="px-4 py-2 text-white bg-red-600 rounded"
        onClick={onClick}
    >
        Confirm
    </button>
);

const CancelButton = ({ onClick }) => (
    <button
        className="px-4 py-2 text-black bg-gray-200 rounded"
        onClick={onClick}
    >
        Cancel
    </button>
);

const ConfirmPushMissionModal = ({ onConfirm, title, message, close, activeIndex = 0, maxIndex }) => {
    const [data, setData] = useState({
        activeIndex,
    })
    const handleChange = (name, value) => {
        setData(prev => ({ ...prev, [name]: value }))
    }

    useEffect(() => {
        handleChange("activeIndex", activeIndex)
    }, [activeIndex])
    return (
        <div className="fixed inset-0 z-[999] flex items-center justify-center pointer-events-none">
            <div className="bg-white dark:bg-[#151515] p-6 shadow-lg relative w-full mt-auto pointer-events-auto">
                <button
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                    onClick={close}
                >
                    <XIcon className="size-4" />
                </button>
                <h2 className="mb-4 text-lg font-semibold text-gray-700 dark:text-white">Confirm Push Mission</h2>
                <div className='flex items-center gap-3 mb-2'>
                    <p className="text-gray-600 dark:text-gray-300">Next Target is Waypoint</p>
                    <InputBox
                        value={!isNaN(parseInt(data.activeIndex)) ? data.activeIndex + 1 : data.activeIndex}
                        onChange={(e) => handleChange("activeIndex", !isNaN(parseInt(e.target.value)) ? e.target.value - 1 : e.target.value)}
                        type="number"
                        min={1}
                        max={maxIndex}
                        // placeholder={`Enter number between 1 and ${maxIndex + 1}`}
                        wrapperClassName={"w-14"}
                    />
                </div>
                <p className='text-red-500 mb-6'>The vessel will carry on this mission from this waypoint</p>
                <div className="flex justify-end gap-4 mt-8">
                    <CancelButton onClick={close} />
                    <ConfirmButton onClick={() => onConfirm(data)} />
                </div>
            </div>
        </div>
    );
};

export default ConfirmPushMissionModal;

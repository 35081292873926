import classNames from 'classnames'
import React from 'react'
import ShipIcon from "../../assets/img/icons/ship.svg";
import BatteryIcon from "../../assets/img/icons/battery-saver.svg";
import AnchorIcon from "../../assets/img/icons/anchor.svg";

const NumericalWidget = ({ data, config }) => {
    console.log(data)
    const indicators_map = {
        battery_voltage_minimum: {
            icon: BatteryIcon,
            unit: 'w/h'
        },
        distance_to_waypoint: {
            icon: ShipIcon,
            unit: 'Km'
        },
        stationkeeping_endtime: {
            icon: AnchorIcon,
            unit: 'h'
        },
        average_power_consumption: {
            icon: BatteryIcon,
            unit: 'w/h'
        },
        battery_voltage_average: {
            icon: BatteryIcon,
            unit: 'w'
        }
    }
    const indicator = Object.keys(config.config)[0];
  return (
    <div className="flex flex-col">
        <div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white">
            {config.name}
        </div>
        <div className="h-44 w-full mt-1 flex flex-col items-center justify-center">
              <p className="text-6xl font-black tracking-[2.56px] font-semibold text-blue-500">{data[indicator].toFixed(2)}</p>
            <div className="shrink-0 mt-2 h-px bg-blue-500 border border-blue-500 border-solid w-[117px]" />
            <div className="flex gap-5 justify-between mt-2 max-w-full text-xl tracking-wider whitespace-nowrap w-[117px]">
                <img src={indicators_map[indicator].icon} className="flex-1 size-6" />
                <p className="text-blue-500 flex-1">{indicators_map[indicator].unit}</p>
            </div>
        </div>
    </div>
  )
}

export default NumericalWidget
import classNames from 'classnames'
import React from 'react'

const ErrorsWidget = ({ data, config }) => {
    console.log(data)
  return (
    <div className="h-full widget-inner overflow-auto rounded flex flex-col self-stretch text-xs font-semibold tracking-wide text-neutral-950 text-opacity-50 border border-solid border-neutral-950 border-opacity-10 dark:text-white dark:text-opacity-50 dark:border-white dark:border-opacity-50">
        {Object.entries(config).map(([key, value]) => 
            <div className="flex items-center justify-between cursor-pointer audio-row bg-[#eff2f4] px-5 py-1 w-full border-t border-solid border-neutral-950 border-opacity-10 dark:bg-[#151515] dark:border-white dark:border-opacity-50">
                {value}
                <div className={classNames('h-full aspect-square', data[key] > 0 ? 'bg-red-500' : 'bg-green-500')}></div>
            </div>
        )}
    </div>
  )
}

export default ErrorsWidget
import React from 'react';
import { ReactComponent as XIcon } from "../assets/img/icons/x.svg";

const SateliteMessagesModal = ({ data, onConfirm, close }) => {
    if (!data?.length) {
        return <h2>No data</h2>
    }
    const headers = Object.keys(data[0])

    return (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white dark:bg-[#151515] p-6 rounded-lg shadow-lg relative w-full flex flex-col mx-8 h-[500px] overflow-hidden">
                <button
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                    onClick={close}
                >
                    <XIcon className="size-4" />
                </button>
                <h2 className="mb-4 text-lg font-semibold text-gray-700 dark:text-white">Satelite message</h2>
                <div className='flex items-center gap-4 text-[12px]'>
                    <p className='text-[14px]'>Current position</p>
                    <p><b>latitude</b> {data[data.length - 1].latitude}</p>
                    <p><b>longditude</b> {data[data.length - 1].longditude}</p>
                </div>
                <div className='overflow-auto flex-auto'>
                    <table className="min-w-full h-full text-[12px] whitespace-nowrap">
                        <thead className="bg-white dark:bg-[#0B0B0B] sticky top-0">
                            <tr>
                                {
                                    headers.map((header, index) => {
                                        return (
                                            <th key={index} className="text-left py-1 px-2">
                                                {header.replaceAll("_", " ")}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.toSorted((a, b) => a.timestamp + b.timestamp).map((row, index) => {
                                    return (
                                        <tr key={row.id} className={`${index % 2 ? 'bg-[#fbfbfb]' : 'bg-gray-100s'}  border-t border-solid border-neutral-950 border-opacity-10 text-left`}>
                                            {
                                                headers.map((header, index) => (
                                                    <td key={index} className="py-1 px-2">{
                                                        ["createdAt", "updatedAt"].includes(header)
                                                            ? new Date(row[header]).toLocaleDateString()
                                                            : row[header]
                                                    }</td>
                                                ))
                                            }
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SateliteMessagesModal;

import React, {useContext, useEffect, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import {ThemeContext} from '../App';
import "mapbox-gl/dist/mapbox-gl.css";
import api from "../api";
import indicatorIcon from "../assets/img/icons/indicator.svg";

const MapboxComponent = ({pointClickCallback}) => {
    const {theme} = useContext(ThemeContext);
    const [map, setMap] = useState(null);
    const [allVessel, setAllVessel] = useState([]);

    const getOneVessel = async (id) => {
        try {
            const res = await api.fleet.getLastHistory(id);
            return res.data;
        } catch (error) {

            return null;
        }
    };

    const getAllFleets = async () => {
        const res = await api.fleet.getAllVessel();
        const vesselList = res.data;

        const detailedVessels = await Promise.all(
            vesselList.map(async (vessel) => {
                const detail = await getOneVessel(vessel.id);
                return {...vessel, history: detail};
            })
        );
        setAllVessel(detailedVessels);
    };

    useEffect(() => {
        getAllFleets();
    }, []);

    useEffect(() => {
        if (map) return;

        mapboxgl.accessToken = process.env.REACT_APP_MAPBOXKEY;
        const currentMap = new mapboxgl.Map({
            container: 'map-container',
            style: theme ? 'mapbox://styles/maximokolzin/clth2mbte018x01pj2usp8uxu' : 'mapbox://styles/maximokolzin/clth258fl00br01nr4uhb69vt',
            center: [-1.653313372610019, 50.520633867967206],
            zoom: 6,
        });

        setMap(currentMap);
    }, [theme]);

    const createVesselMarker = (map, vessel, vesselData) => {
        if (!vesselData) return;

        const {latitude, longitude, target_heading} = vesselData;

        const el = document.createElement('div');
        el.className = 'vessel-marker';
        el.style.backgroundImage = `url(${indicatorIcon})`;
        el.style.width = '36px';
        el.style.height = '49px';
        el.style.backgroundSize = 'cover';
        el.style.transform = `rotate(${target_heading}deg)`;

        const marker = new mapboxgl.Marker(el)
            .setLngLat([longitude, latitude])
            .addTo(map);

        const popupContent = `
      <div>
        <strong>${vessel.name}</strong><br/>
        IMEI: ${vessel.imei}<br/>
        Latitude: ${latitude}<br/>
        Longitude: ${longitude}<br/>
        Heading: ${target_heading || 'N/A'}<br/>
        Fleet ID: ${vessel.fleetId || 'N/A'}
      </div>
    `;

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
        }).setHTML(popupContent);

        marker.getElement().addEventListener('mouseenter', (e) => {
            const markerCoordinates = marker.getLngLat();
            popup.setLngLat(markerCoordinates).addTo(map);
        });
        marker.getElement().addEventListener('mouseleave', () => popup.remove());
    };

    const getLatestPoint = (vessels) => {
        return vessels.reduce((latest, vessel) => {
            const latestRecord = vessel.history?.[0]?.payload;
            return (latestRecord && (!latest || latestRecord.timestamp > latest.timestamp))
                ? latestRecord
                : latest;
        }, null);
    };


    useEffect(() => {
        if (map && allVessel.length) {
            allVessel.forEach(vessel => {
                const latestHistory = vessel.history?.[0]?.payload;
                if (latestHistory) {
                    createVesselMarker(map, vessel, latestHistory);
                }
            });
            const latestPoint = getLatestPoint(allVessel);
            if (latestPoint) {
                map.flyTo({
                    center: [latestPoint.longitude, latestPoint.latitude],
                    zoom: 10,
                    speed: 0.5,
                });
            }

        }
    }, [map, allVessel]);


    return <div id="map-container" style={{width: '100%', height: '100%'}}></div>;
};

export default MapboxComponent;

import { useEffect, useState } from "react";
import { ReactComponent as BinIcon } from "../../../assets/img/icons/bin.svg";


const PopupContent = ({ data, isHistorical, currentMission, showLoop }) => {
    let distance = data && !isNaN(parseFloat(data?.distanceToPrevious)) ? parseFloat(data?.distanceToPrevious) : 0;

    // const hours = ((distance / 1000) / 30);
    const latDisplay = data?.lat
    const lngDisplay = data?.lng || data?.lon
    const isComplete = currentMission?.status === 'complete'
    const disabled = isHistorical || isComplete

    // const [showLoop, setShowLoop] = useState(false)
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setShowLoop(data?.index === window.wayPoints?.length - 1)
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, [])




    return <div className="flex flex-col gap-[4px] min-w-[175px]">
        <div className="flex items-center justify-between w-full">
            <div className="w-full">
                {!!distance && <p className="text-[12px] font-semibold text-[#0B0B0B80]">Distance: {distance.toFixed(2)} nm</p>}
                <input disabled={disabled} onChange={() => { }} className="input lat-input" type="text" value={latDisplay} />
                <input disabled={disabled} onChange={() => { }} className="input lng-input mt-1" type="text" value={lngDisplay} />
            </div>
            {
                !disabled && (
                    <div className="delete-waypoint-btn  cursor-pointer">
                        <div className="pointer-events-none">
                            <BinIcon />
                        </div>
                    </div>
                )
            }
        </div>
        {showLoop && <div className="flex items-center gap-[4px]">
            <p className="text-[12px] flex-auto font-semibold text-[#0B0B0B80]">Loop from Waypoint</p>
            <div
                className="h-[32px] rounded-[4px] border border-[#0B0B0B40] py-[4px] px-[8px] flex items-center gap-[4px] w-[32px]">
                <input type="text" className="bg-inherit block w-full h-[16px] text-[12px] repeat-trajectory-input"
                    disabled={isComplete} readOnly={isComplete} placeholder="X" value={data?.repeat_trajectory || ""}
                    onChange={() => { }} />
            </div>
        </div>}
        <div className="flex items-center gap-[4px] whitespace-nowrap">
            <p className="text-[12px] font-semibold text-[#0B0B0B80]">Radius</p>
            <div
                className="h-[32px] flex-auto rounded-[4px] border border-[#0B0B0B40] py-[4px] px-[8px] flex items-center gap-[4px]">
                <input className="bg-inherit block w-full h-[16px] text-[12px] target-radius-input"
                    disabled={isComplete} readOnly={isComplete} placeholder="X" type="number" min={1} max={65535}
                    value={data?.targetRadius || 1} onChange={() => { }} />
            </div>
            <p className="text-[12px] font-semibold text-[#0B0B0B80]">m</p>
        </div>
        <div className="flex gap-[4px] whitespace-nowrap flex-col">
            <p className="text-[12px] font-semibold text-[#0B0B0B80]">Stationkeeping</p>
            <div className="flex items-center gap-[4px] whitespace-nowrap">
                <div
                    className="h-[32px] flex-auto rounded-[4px] border border-[#0B0B0B40] py-[4px] px-[8px] flex items-center gap-[4px]">
                    <input className="bg-inherit block w-full h-[16px] text-[12px] station-days-input"
                        disabled={disabled} readOnly={disabled} placeholder="X" type="number" min={0} max={65535}
                        value={data?.stationDays || 0} onChange={() => {
                        }} />
                </div>
                <p className="text-[12px] font-semibold text-[#0B0B0B80]">d</p>
                <div
                    className="h-[32px] flex-auto rounded-[4px] border border-[#0B0B0B40] py-[4px] px-[8px] flex items-center gap-[4px]">
                    <input className="bg-inherit block w-full h-[16px] text-[12px] station-hours-input"
                        disabled={disabled} readOnly={disabled} placeholder="X" type="number" min={0} max={65535}
                        value={data?.stationHours || 0} onChange={() => {
                        }} />
                </div>
                <p className="text-[12px] font-semibold text-[#0B0B0B80]">h</p>
            </div>
        </div>
    </div>
}

export default PopupContent
import { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import api from '../api';
import { useClickOutside } from '../helpers/useClickOutside';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCreateFilter, fetchFilterCalendar} from '../store/thunks/filtersThunks';
import {getCalendarData, setActiveFilter} from "../store/slices/filtersSlice";

export const FilterList = ({ filters, deleteFilter, selectedFilter, onFilterSelect, onFilterClear }) => {
  const [filtersDropdownOpen, setFiltersDropdownOpen] = useState(false);

  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setFiltersDropdownOpen(false));

  const handleFilterSelect = (filter) => {
    onFilterSelect(filter);
    setFiltersDropdownOpen(false);
  }
  // console.log(filters)

  return (
    <div className='flex gap-2 items-center'>
      <div className="relative" ref={dropdownRef}>
        <div
          onClick={() => setFiltersDropdownOpen(!filtersDropdownOpen)}
          className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
        >
          {selectedFilter && selectedFilter.name ? (
            <>
              <strong>{selectedFilter.name}</strong> - Vessel: {selectedFilter.vessel.name}, Date Range: {new Date(selectedFilter.startDate)?.toLocaleDateString()} to {new Date(selectedFilter.endDate)?.toLocaleDateString()}
            </>
          ) : (
            <>Saved Filters</>
          )}
        </div>
        {filtersDropdownOpen && (
          <ul className="z-[10000] absolute flex flex-col right-[-1px] top-[calc(100%+2px)] w-full rounded-b-[2px] rounded-t-0 border-t-0 border border-[#0B0B0B1A] bg-white min-w-[calc(100%+2px)] max-h-[180px] overflow-y-auto">
            {filters.length ? filters.map((filter, index) => (
              <li key={index} className="flex items-center gap-3 hover:bg-slate-50 p-2">
                <div onClick={() => handleFilterSelect(filter)} className="flex-auto truncate cursor-pointer">
                  <strong>{filter.name}</strong> - Vessel: {filter.vessel.name}, Date Range: {new Date(filter.startDate)?.toLocaleDateString()} to {new Date(filter.endDate)?.toLocaleDateString()}
                </div>
                <button
                  className='group flex items-center justify-center ml-auto p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]'
                  onClick={() => deleteFilter(filter.id)}
                >
                  Delete
                </button>
              </li>
            )) : (
              <li className='flex items-center gap-3 text-center p-2'>
                There is no saved filters yet
              </li>
            )}
          </ul>
        )}
      </div>
      {/* {
        selectedFilter ?
          <button
            onClick={onFilterClear}
            className='group flex items-center justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]'
            type="button">
            Clear filter
          </button>
          :
          null
      } */}
    </div>
  );
};

const ConfigHeader = ({ onClose }) => (
  <div className="flex gap-5 justify-between px-2 py-1 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:text-white dark:bg-[#151515]">
    <div>Create filter</div>
    <button onClick={onClose}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddcb33ebb0cb0d777eaebba2ca6701637f967354c4f2b41114a225bd26c5a850?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
        className="shrink-0 my-auto w-3 aspect-square close-icon"
      />
    </button>
  </div>
);

export const SaveFilterModal = ({ filters, onSubmit, onClose }) => {
  const [name, setName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit && onSubmit({ ...filters, name });
    onClose && onClose();
  };

  return (
    <div style={{ position: 'fixed', width: 362, top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}
      className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 max-w-[336px] border border-solid border-neutral-950 border-opacity-30 dark:border-[#ffffff1a] dark:bg-[#1b1b1b]">
      <ConfigHeader onClose={onClose} />
      <div className="flex flex-col justify-end px-3 pb-3 mt-4 w-full font-semibold text-neutral-950">
        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-2">
          <div className='flex flex-col col-span-2'>
            <label>Filter Name:</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              type="text"
              placeholder='Filter Name'
              className="p-1 bg-white dark:bg-[#0B0B0B] rounded border border-solid border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
            />
          </div>
          <div className='col-span-2'>
            <div className='flex justify-between gap-4'>
              <button
                onClick={onClose}
                className='group flex items-center justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]'
                type="button"
              >
                Cancel
              </button>
              <button
                className='group flex items-center justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]'
                type="submit"
              >
                Add Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

const FiltersForm = ({ selectedFilter, onSubmit, selectFilter, onFilterClear, className = "" }) => {
  const [vessel, setVessel] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filtersToSave, setFiltersToSave] = useState(null);
  const [allVessels, setAllVessel] = useState([]);
  const  dispatch = useDispatch()
  const calendarData = useSelector(getCalendarData)

  const [vesselsDropdownOpen, setVesselsDropdownOpen] = useState(false);

  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setVesselsDropdownOpen(false));

  const handleVesselSelect = (vessel) => {
    setVessel(vessel);
    setVesselsDropdownOpen(false);
  }

  useEffect(() => {
        dispatch(fetchFilterCalendar({vesselId: vessel}))
  }, [vessel])

  const getAllFleets = async () => {
    let res = await api.fleet.getAllVessel();
    setAllVessel(res.data);
  }

  useEffect(() => {
    getAllFleets();
  }, []);
  useEffect(() => {
    if (selectedFilter) {
      setVessel(selectedFilter.vessel);
      setStartDate(selectedFilter.startDate ? selectedFilter.startDate : null);
      setEndDate(selectedFilter.endDate ? selectedFilter.endDate : null);
    }
  }, [selectedFilter]);

  const handleSave = (e) => {
    e.preventDefault();
    setFiltersToSave({ vessel, startDate, endDate });
  };

  const handleSelectFilter = () => {
    if (vessel || startDate || endDate) {
      selectFilter({ vessel, startDate, endDate });
    }
  };
  return (
    <>
      <form onSubmit={handleSave} className={`flex items-center gap-2 ${className}`}>
        <div className='flex flex-col col-span-2'>
          <div className="relative" ref={dropdownRef}>
            <div
              onClick={() => setVesselsDropdownOpen(!vesselsDropdownOpen)}
            >
              <input
                value={allVessels.find(item => item.id === vessel)?.name || ''}
                onChange={(e) => setVessel(e.target.value)}
                type="text"
                required
                placeholder='Vessel name'
                className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
              />
            </div>
            {vesselsDropdownOpen && (
              <ul className="absolute flex flex-col right-[-1px] top-[calc(100%+2px)] w-full rounded-b-[2px] rounded-t-0 border-t-0 border border-[#0B0B0B1A] bg-white min-w-[calc(100%+2px)] z-[1000] max-h-[180px] overflow-y-auto">
                {allVessels.length ? allVessels.map((vessel, index) => (
                  <li key={index} className="flex items-center gap-3 hover:bg-slate-50 p-2">
                    <div onClick={() => handleVesselSelect(vessel.id)} className="flex-auto truncate cursor-pointer">
                      {vessel.name}
                    </div>
                  </li>
                )) : (
                  <li className='flex items-center gap-3 text-center p-2'>
                    There is no vessels yet
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className='flex flex-col'>
          <DatePicker
            className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
            // className="px-2 min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
            toggleCalendarOnIconClick
            showTimeSelect
            selected={startDate ? new Date(startDate) : null}
            placeholderText="Start Date"
            onChange={(date) => setStartDate(date.toISOString())}
            required
            dateFormat="dd/MM/yy, HH:mm"
            highlightDates={calendarData?.map(item => new Date(item.date))}
          />
        </div>
        <div className='flex flex-col'>
          <DatePicker
            className="p-1 truncate bg-white dark:bg-[#0B0B0B] rounded border border-solid w-60 border-neutral-950 border-opacity-30 text-neutral-950 text-opacity-50 font-normal leading-5 min-h-8 dark:text-white dark:border-white dark:border-opacity-50"
            // className="px-2 min-h-8 text-xs tracking-wide whitespace-nowrap bg-white rounded border border-solid border-[color:var(--black-25,rgba(11,11,11,0.25))] text-neutral-950 text-opacity-50 dark:bg-[#0B0B0B]"
            toggleCalendarOnIconClick
            showTimeSelect
            selected={endDate ? new Date(endDate) : null}
            placeholderText="End Date"
            onChange={(date) => setEndDate(date.toISOString())}
            required
            dateFormat="dd/MM/yy, HH:mm"
            highlightDates={calendarData?.map(item => new Date(item.date))}
          />
        </div>
        <button
          disabled={!(vessel && startDate && endDate)}
          onClick={handleSelectFilter}
          type="button"
          className={`${!(vessel && startDate && endDate) ? "opacity-30" : ""} flex items-center justify-center gap-1 font-semibold h-8 px-3 text-xs tracking-[4%] rounded border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]`}
        >
          Explore Data
        </button>
        {/* <button
          onClick={onFilterClear}
          type="button"
          className='flex items-center justify-center gap-1 font-semibold h-8 px-3 text-xs tracking-[4%] rounded border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]'
        >
          Clear filters
        </button> */}
        <button
          disabled={!(vessel && startDate && endDate)}
          type="submit"
          className={`${!(vessel && startDate && endDate) ? "opacity-30" : ""} flex items-center justify-center gap-1 font-semibold h-8 px-3 text-xs tracking-[4%] rounded border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]`}
        >
          Save Filter
        </button>
      </form>
      {filtersToSave && (
        <SaveFilterModal
          filters={filtersToSave}
          onSubmit={onSubmit}
          onClose={() => setFiltersToSave(null)}
        />
      )}
    </>
  );
};

const NamedFilter = ({ onChange, saveFilters, calendarData=null }) => {
  const [filters, setFilters] = useState([]);
  // const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters")) || []);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const dispatch = useDispatch()
  const handleFilterSubmit = (newFilter) => {
    console.log(newFilter)
    dispatch(fetchCreateFilter({
      name: newFilter.name,
      startDate: newFilter.startDate,
      endDate: newFilter.endDate,
      vesselId: newFilter.vessel
    }))
  };



  const handleFilterDelete = (name) => {
    const newFilters = filters?.filter(filter => filter.name !== name);
    setFilters(newFilters);
    localStorage.setItem("filters", JSON.stringify(newFilters))

    if (selectedFilter && (selectedFilter.name === name)) {
      setSelectedFilter(null)
    }
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
  };

  const handleFilterClear = () => {
    setSelectedFilter(null);
  };

  useEffect(() => {
    onChange && onChange(selectedFilter)
    dispatch(setActiveFilter({...selectedFilter, name: "Explore Data", vesselId: selectedFilter?.vessel}))
  }, [selectedFilter])

  return (
    <div className="flex flex-col gap-2 mb-4">
      <div className='flex justify-between gap-2'>
        <FiltersForm
          selectedFilter={selectedFilter}
          onSubmit={handleFilterSubmit}
          selectFilter={handleFilterSelect}
          onFilterClear={handleFilterClear}
          calendarData={calendarData}
        />
        {/* <FilterList
          filters={filters}
          deleteFilter={handleFilterDelete}
          selectedFilter={selectedFilter}
          onFilterSelect={handleFilterSelect}
          onFilterClear={handleFilterClear}
        /> */}
      </div>
      {/* {
        selectedFilter ?
          <div className='flex items-center gap-2'>
            <p className='inline-flex gap-1'>
              Vessel :
              <strong>{selectedFilter.vessel}</strong>,
              Date Range:
              <strong>{new Date(selectedFilter?.startDate)?.toLocaleDateString()}</strong>
              to
              <strong>{new Date(selectedFilter?.endDate)?.toLocaleDateString()}</strong>
            </p>
            <button
              onClick={handleFilterClear}
              className='group flex items-center justify-center p-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917]'
              type="button">
              Clear filter
            </button>
          </div>
          : null
      } */}

    </div>
  );
};

export default NamedFilter;

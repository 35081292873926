import React, {useState, useEffect, useRef} from 'react';
import {Slider} from './Slider';


const TimelineSimulationNew = ({currentMission, sliderPercentage, setSliderPercentage, windy, callBackSliderDate}) => {

    const countEndDate = (start, count) => {
        const date = new Date(start);
        date.setUTCDate(date.getUTCDate() + count);
        return date.toISOString();
    };

    const startDateProp = currentMission?.start ? new Date(currentMission.start) : new Date();

    const endDateProp = currentMission?.status === "in_progress"
        ? countEndDate(new Date(), 0)
        : currentMission?.end
            ? new Date(currentMission.end)
            : new Date(countEndDate(startDateProp, 6));


    const calculateTimeline = (startDateProp, endDateProp) => {
        const startDate = new Date(startDateProp);
        const endDate = new Date(endDateProp);

        const getDaysArray = (s, e) => {
            const days = [];
            const currentDate = new Date(s);
            while (currentDate <= e) {
                days.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return days;
        };

        const todayDate = new Date();
        const todayDateString = todayDate.toDateString();
        const currentHour = todayDate.getHours();
        const daysArray = getDaysArray(startDate, endDate);
        const totalHours = daysArray.length * 24;
        const isEndToday = todayDateString === endDate.toDateString();
        const adjustedTotalHours = isEndToday ? totalHours - (24 - currentHour - 1) : totalHours;

        let cumulativeHours = 0;

        const timeline = daysArray.map((dateItem, index) => {
            const isToday = dateItem.toDateString() === todayDateString;
            const isLastFullDay = index === daysArray.length - 2 && isEndToday;

            const hourCount = isLastFullDay ? 24 : (isToday && isEndToday ? currentHour + 1 : 24);

            const times = new Array(hourCount).fill('').map((_, timeIndex) => {
                const percent = ((cumulativeHours + timeIndex) / adjustedTotalHours) * 100;
                return {
                    time: `${timeIndex < 10 ? '0' + timeIndex : timeIndex}:00`,
                    percent: Math.floor(percent),
                };
            });

            cumulativeHours += hourCount;

            return {
                index,
                date: dateItem.toDateString(),
                day: isToday && !isLastFullDay ? `(Today)` : `(${dateItem.toLocaleDateString('en', {weekday: 'long'})})`,
                percentage: Math.floor(Math.min(70, (cumulativeHours / adjustedTotalHours) * 100)),
                times,
            };
        });

        if (isEndToday) {

            timeline.push({
                index: timeline.length,
                date: todayDate.toDateString(),
                day: "(Today)",
                percentage: Math.floor((cumulativeHours / adjustedTotalHours) * 100),
                times: new Array(currentHour + 1).fill('').map((_, timeIndex) => ({
                    time: `${timeIndex < 10 ? '0' + timeIndex : timeIndex}:00`,
                    percent: Math.floor(((cumulativeHours + timeIndex) / adjustedTotalHours) * 100),
                })),
            });

            timeline.push({
                index: timeline.length + 1,
                date: `${currentHour < 10 ? '0' + currentHour : currentHour}:${todayDate.getMinutes() < 10 ? '0' + todayDate.getMinutes() : todayDate.getMinutes()}`, // Shows current time in HH:MM format

                day: "Now",
                percentage: 100,
                times: [
                    {
                        time: `${currentHour < 10 ? '0' + currentHour : currentHour}:00`,
                        percent: 100,
                    },
                ],
            });
        }

        return timeline;
    };

    const timeline = calculateTimeline(startDateProp, endDateProp);


    const getDateFromSliderValue = (value, startDate, endDate) => {
        const start = new Date(startDate).getTime()
        const end = new Date(endDate).getTime()
        const timeRange = end - start
        const timeAtValue = start + (value / 100) * timeRange
        return new Date(timeAtValue)
    };


    useEffect(() => {
        const findTimestamp = (val) => {

            const percentageBreakpoints = timeline?.map(el => el.percentage);

            percentageBreakpoints.forEach((value, index) => {
                const prevValue = percentageBreakpoints[index - 1] || 0
                if (prevValue <= val && value >= val) {
                    const el = timeline?.find(el => el.percentage === value)
                    if (el) {
                        if (windy) {
                            const a = new Date(el.date).getTime()
                            windy.store.set('timestamp', a)
                        }
                    }

                }
            })
        }

        callBackSliderDate && callBackSliderDate(getDateFromSliderValue(sliderPercentage, startDateProp, endDateProp))

        findTimestamp(sliderPercentage)
    }, [sliderPercentage])


    const sliderContainerRef = useRef(null);
    const [hoveredTime, setHoveredTime] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({left: 0});

    const handleMouseMove = (e) => {
        if (sliderContainerRef.current) {
            const containerRect = sliderContainerRef.current.getBoundingClientRect();
            const mouseX = e.clientX - containerRect.left;
            const containerWidth = containerRect.width;
            const hoverPercentage = (mouseX / containerWidth) * 100;
            const constrainedPercentage = Math.max(0, Math.min(100, hoverPercentage));
            const date = getDateFromSliderValue(constrainedPercentage, startDateProp, endDateProp);
            setHoveredTime(date);
            setTooltipPosition({left: mouseX});
        }
    };


    return <div
        className='h-[132px] shadow-[0px_4px_8px_0px_#0000001A] border border-[#E4232040] rounded-[4px] p-[8px] bg-[#FBFBFB]'
        key={"isNotSimulating"}>
        <div
            className='rounded-[4px] overflow-auto border border-[#0B0B0B1A] bg-[#EFF2F4] p-[4px] flex items-center gap-[8px] min-h-[117px]'>
            <div className='h-full flex-1'>
                <ul className='flex items-center justify-between gap-5'>
                    {timeline.map((item, index) => (
                        <li
                            key={index}
                            className='flex flex-col items-center gap-[4px]'
                        >
                            <p className='text-[10px] leading-[13px] tracking-[0.04em] text-center text-[#0B0B0B80]'>
                                {item.day === "Now" ? item.date : new Date(item.date)?.toLocaleDateString()} <br/>
                                {item.day}
                            </p>
                            <div className='h-[4px] w-[1px] bg-[#0B0B0B40]'/>
                        </li>
                    ))}
                </ul>

                <div className='px-[12px] py-[4px]'>
                    <div ref={sliderContainerRef} onMouseMove={handleMouseMove}
                         onMouseLeave={() => setHoveredTime(null)}>
                        {hoveredTime && (
                            <div
                                className="absolute top-[100px] bg-gray-700 text-white text-xs p-1 rounded"
                                style={{left: `${tooltipPosition.left}px`, transform: 'translateX(-50%)'}}
                            >
                                {hoveredTime.toLocaleDateString('en-GB').replace(/\//g, '.')} {hoveredTime.toLocaleTimeString('en-GB', {hour12: false})}
                            </div>
                        )}
                        <Slider
                            start={sliderPercentage}
                            onChange={(e) => {
                                console.log(e)
                                setSliderPercentage(+e);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default TimelineSimulationNew;

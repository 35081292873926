import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: true,
      },
    },
    y: {
      grid: {
        borderDash: [8, 4],
      },
      beginAtZero: true,
      ticks: {
        callback: function(value) {
          return `${value}`;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += `${context.parsed.y}`;
          }
          return label;
        },
      },
    },
  },
};

const labels = ['Average Wind Speed', 'GPS Velocity'];

const data = {
  labels,
  datasets: [
    {
      label: 'Speed',
      data: [25, 53], // Percentage values for the first dataset
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      barThickness: 40
    },
    {
      label: 'Test',
      data: [25, 53], // Percentage values for the first dataset
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      barThickness: 40
    },
  ],
};

export const BarChart = ({ config, data }) => {
  const dataOptions = {
  labels: Object.values(config.config),
  datasets: [
    {
      label: 'Value',
      data: Object.keys(config.config).map(key => data[key]), // Percentage values for the first dataset
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      barThickness: 40
    },
  ],
};
  return (
    <div className="widget-inner">
        <div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white">
            {config.name}
        </div>
        <div className="h-44 w-full mt-1">
            <Bar options={options} data={dataOptions} />
        </div>
    </div>
  );
};
import InputBox from "../components/base/InputBox";
import React, { useState, useRef, useEffect } from "react";
import PanelButton from "../components/base/PanelButton";
import {useDispatch} from "react-redux";
import {fetchCreateFleet, fetchCreateMission, fetchCreateVessel} from "../store/thunks/fleetThunks";
import DatePicker from 'react-datepicker';
import { useNavigate } from "react-router-dom";
import { useClickOutside } from '../helpers/useClickOutside';
import api from '../api';
import {addNewMission} from "../store/slices/missionSlice";

const ConfigHeader = ({onClose, type}) => (
    <div className="flex gap-5 justify-between px-2 py-1 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-50 dark:text-white dark:bg-[#151515]">
        <div>Duplicate Mission</div>
        <button onClick={onClose}>
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddcb33ebb0cb0d777eaebba2ca6701637f967354c4f2b41114a225bd26c5a850?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
                className="shrink-0 my-auto w-3 aspect-square close-icon"
            />
        </button>
    </div>
);

export const DuplicateModal = ({onClose, data}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        name: ''
    });
    
    useEffect(() => {
        console.log(data, 'data')

        if (data && data?.description) {
            setFormData(prev => ({...prev, name: `copy of ${data?.description}`}))
        }

    }, [data])

    console.log('formData:', formData);

    const [formErrors, setFormErrors] = useState({})

    const handleChange = (name, val) => {
        setFormData(prevState => ({...prevState, [name]: val}))
        setFormErrors(prev => ({...prev, [name]: false}))
    }

    const requieredFields = ['name']

    const submit = async () => {
        const fields = Object.entries(formData)
        let anyErrors = false
        fields.forEach(field => {
            console.log(field)
            if (requieredFields.includes(field[0]) && !field[1]) {
                setFormErrors(prev => ({...prev, [field[0]]: true}))
                anyErrors = true
            }
        })
        if (anyErrors) return


        setIsLoading(true)

        console.log("formData:", formData)

        const res = await api.fleet.cloneMissionWithName({id: data.id, name: formData.name})

        console.log(res, 'res')

        if (res.success) {
            dispatch(addNewMission(res.data))
        }

        setIsLoading(false)
        onClose && onClose()


    }

    return (
        <div style={{
            position: 'fixed',
            width: 362,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }}
             className="flex flex-col text-xs tracking-wide rounded bg-zinc-50 max-w-[336px] border border-solid border-neutral-950 border-opacity-30 dark:border-[#ffffff1a] dark:bg-[#1b1b1b]">
            <ConfigHeader onClose={onClose} />

            <div className="flex flex-col justify-end px-3 pb-3 w-full font-semibold text-neutral-950">

                <div className="mt-[12px] mb-[8px]">Name mission</div>
                <InputBox
                    type='text'
                    placeholder='Name'
                    value={formData.name}
                    wrapperClassName={`${formErrors['name'] ? 'input-error' : ''} `}
                    disabled={isLoading}
                    onChange={(e) => handleChange('name', e.target.value)}
                />

                <PanelButton
                    className='w-fit ml-auto mt-[12px]'
                    onClick={submit}
                    disabled={isLoading}
                >
                    Duplicate
                </PanelButton>


            </div>


        </div>
    )
}
export const convertToDegreesMinutes = (coordinate, isLatitude) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutes = ((absolute - degrees) * 60);
    const direction = coordinate >= 0 
    ? (isLatitude ? 'N' : 'E')
    : (isLatitude ? 'S' : 'W');
    
    const formattedMinutes= minutes.toString().slice(0, 7)
    
    return `${degrees}°${formattedMinutes}’${direction}`;
};

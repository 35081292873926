const base = 'charts';

export const chartsApi = (instance) => ({
    async getAllCharts() {
        try {
            const { data } = await instance.get(base, {
                params: {
                    no_limit: true
                }
            });
            return data;
        } catch (e) {
            return e.response.data
        }
    },
});

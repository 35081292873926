import {createSlice} from '@reduxjs/toolkit'
import {
    createApiKeyThunk, deleteApiKeyThunk,
    fetchApiKeyThunk,
    fetchUserThunk,
    logoutThunk, setLastCoordsThunk,
    updateApiKeyThunk
} from '../thunks/applicationThunks';

const initialState = {
    authorized: null,
    user: null,
    token: null,
    apiKey: null,
    loading: false,
}

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setAuthorized: (state, action) => {
            state.authorized = action.payload;
        },

        setUser: (state, action) => {
            state.user = action.payload;
        },

        setToken: (state, action) => {
            state.token = action.payload;
        },
    },

    extraReducers(builder) {
        builder.addCase(fetchUserThunk.fulfilled, (state, action) => {
            state.user = action.payload;
            state.authorized = true;
        });

        builder.addCase(fetchUserThunk.pending, (state, action) => {
            state.authorized = null;
        });

        builder.addCase(fetchUserThunk.rejected, (state, action) => {
            localStorage.removeItem('authToken');
            state.authorized = false;
            state.token = null;
        });

        builder.addCase(logoutThunk.fulfilled, (state, action) => {
            state.authorized = false;
            state.user = null;
            localStorage.removeItem('authToken');
        });
        builder.addCase(fetchApiKeyThunk.fulfilled, (state, action) => {
            state.apiKey = action.payload;
        });


        builder.addCase(createApiKeyThunk.fulfilled, (state, action) => {
            state.apiKey.push(action.payload);
            state.loading = false;
        });

        builder.addCase(updateApiKeyThunk.fulfilled, (state, action) => {
            // state.apiKey =  state.apiKey.map(one => one.id === action.payload.id ? action.payload : one);
            state.loading = false;
        });
        builder.addCase(setLastCoordsThunk.fulfilled, (state, action) => {
            state.user = {...state.user, lastAddress: action.payload};
            state.loading = false;
        });

        builder.addCase(deleteApiKeyThunk.fulfilled, (state, action) => {
            state.apiKey = state.apiKey.filter(one => one.id !== +action.payload);
            state.loading = false;
        });
    }
})

export const {
    setUser, setAuthorized, setToken,
} = applicationSlice.actions;

export const getUser = (state) => state.application.user;
export const getAuthorized = (state) => state.application.authorized;
export const getToken = (state) => state.application.token;
export const getApiKey = (state) => state.application.apiKey;
export const getLoading = (state) => state.application.loading;

export default applicationSlice.reducer

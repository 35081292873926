export const getMediaUrl = (mediaPath) => {
    if (mediaPath instanceof File) {
        return URL.createObjectURL(mediaPath)
    }
    let apiUrl = process.env.REACT_APP_API_URL;
    if (apiUrl.endsWith('api/')) {
        apiUrl = apiUrl.slice(0, -4);
    }
    return `${apiUrl}${mediaPath}`;
}

import {setLastCoordsThunk} from "../../store/thunks/applicationThunks";

const usersBase = 'users';
const keysBase = 'keys';

export const users = (instance) => ({
    async getUser(payload) {
        const { data } = await instance.get(`${usersBase}/me`);
        return data;
    },

    async updateProfile(payload) {
        const { data } = await instance.put(`${usersBase}/me`, payload);
        return data;
    },

    async updateNotifications(payload) {
        const { data } = await instance.post(`/notifications/me`, payload);
        return data;
    },

    async changePassword(payload) {
        const { data } = await instance.post(`${usersBase}/me/change-password`, payload);
        return data;
    },

    async uploadImage(payload) {
        const { data } = await instance.postForm(`${usersBase}/me`, payload, {
            method: 'put',
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return data;
    },

    async getApiKey() {
        const { data } = await instance.get(keysBase);
        return data;
    },

    async createApiKey(payload) {
        const { data } = await instance.post(keysBase, payload);
        return data;
    },

    async updateApiKey(payload, id) {
        const { data } = await instance.put(`${keysBase}/${id}`, payload);
        return data;
    },

    async deleteApiKey(id) {
        const { data } = await instance.delete(`${keysBase}/${id}`);
        return data;
    },
    async getUserMedia() {
        const { data } = await instance.get(`${usersBase}/media`);
        return data;
    },
    async createUserMedia(payload) {
        const { data } = await instance.post(`${usersBase}/media`, payload);
        return data;
    },
    async deleteUserMedia(id) {
        const { data } = await instance.delete(`${usersBase}/media/${id}`);
        return data;
    },
    async setLastCoords(payload) {
        const {data} = await instance.post(`${usersBase}/address`,  {...payload, isHome: false});
        return data;
    },
});

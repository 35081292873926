const widgetsBase = 'widgets';
const layoutsBase = 'layouts';

export const dashboard = (instance) => ({
    async getAllWidgets(payload) {
        const { data } = await instance.get(widgetsBase, payload);
        return data;
    },

    async getWidgetById(id) {
        const { data } = await instance.get(`${widgetsBase}/${id}`);
        return data;
    },

    async getAllLayouts(payload) {
        const { data } = await instance.get(layoutsBase, payload);
        return data;
    },

    async getLayoutById(id) {
        const { data } = await instance.get(`${layoutsBase}/${id}`);
        return data;
    },
});

import { useClickOutside } from "../helpers/useClickOutside";
import PanelButton from "../components/base/PanelButton";
import React, { useRef } from "react";

const ConfigHeader = ({ onClose }) => (
    <div className="flex gap-5 justify-between px-4 py-2 w-full bg-gray-100 border-b border-solid border-neutral-950 border-opacity-10 text-neutral-950 text-opacity-70 dark:text-white dark:bg-[#151515]">
        <div className="text-base font-semibold">Coordinate System Information</div>
        <button onClick={onClose} aria-label="Close">
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddcb33ebb0cb0d777eaebba2ca6701637f967354c4f2b41114a225bd26c5a850?apiKey=9106ba04c02e4dceae488e75e2ac4862&"
                className="shrink-0 my-auto w-4 aspect-square close-icon"
                alt="Close icon"
            />
        </button>
    </div>
);

export const InfoCoordinateModal = ({ onClose }) => {
    const dropdownRef = useRef();
    useClickOutside(dropdownRef, () => onClose());

    return (
        <div
            ref={dropdownRef}
            style={{
                position: 'fixed',
                width: '90%',
                maxWidth: 400,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
            className="flex flex-col text-sm rounded-lg shadow-lg bg-white dark:bg-[#1b1b1b] border border-solid border-neutral-300 dark:border-[#ffffff1a]"
        >
            <ConfigHeader onClose={onClose} />

            <div className="flex flex-col p-4 w-full text-neutral-800 dark:text-neutral-300">
                <h2 className="text-lg font-semibold mb-2">Coordinate System Details</h2>
                <p className="mb-4">
                    <b>System:</b> WGS84 (World Geodetic System 1984)
                    <br/>
                    <span className="text-sm text-neutral-600 dark:text-neutral-400">
                        We use this coordinate system to provide accurate global positioning.
                    </span>
                </p>

                <p className="mb-4">
                    <b>Format:</b> Latitude, Longitude, and Target Radius (meters)
                    <br/>
                    <span className="text-sm text-neutral-600 dark:text-neutral-400">
                        Ensure that each entry follows this format for accurate processing.
                    </span>
                </p>

                <div
                    className="bg-gray-100 dark:bg-[#2a2a2a] rounded p-3 text-xs text-neutral-800 dark:text-neutral-200">
                    <p><b>Example:</b></p>
                    <table className="w-full text-left font-mono mt-2 border-collapse">
                        <thead>
                        <tr>
                            <th className="border-b border-neutral-300 dark:border-neutral-600 pb-1">Latitude</th>
                            <th className="border-b border-neutral-300 dark:border-neutral-600 pb-1">Longitude</th>
                            <th className="border-b border-neutral-300 dark:border-neutral-600 pb-1">Radius</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="py-1">49.8397</td>
                            <td className="py-1">24.0297</td>
                            <td className="py-1">1</td>
                        </tr>
                        <tr>
                            <td className="py-1">50.4501</td>
                            <td className="py-1">30.5234</td>
                            <td className="py-1">100</td>
                        </tr>
                        <tr>
                            <td className="py-1">48.8566</td>
                            <td className="py-1">2.3522</td>
                            <td className="py-1">50</td>
                        </tr>
                        </tbody>
                    </table>
                </div>


                <PanelButton
                    className="self-end mt-4 w-20"
                    onClick={onClose}
                >
                    Close
                </PanelButton>
            </div>
        </div>
    );
};

import {useSelector} from 'react-redux';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {getAuthorized, getUser,} from '../store/slices/applicationSlice';


const ProtectedRouteLayout = () => {
    const authorized = useSelector(getAuthorized);
    const user = useSelector(getUser)
    const {pathname} = useLocation();

    if (authorized !== true) {
        return null
    }
    const screenAccess = user?.screenAccess || {};
    if (pathname.startsWith('/dashboard') && !screenAccess.dashboard) return <Navigate to="/" replace/>;
    if (pathname.startsWith('/data-explorer') && !screenAccess.explorer) return <Navigate to="/" replace/>;
    if (pathname.startsWith('/map-view') && !screenAccess.map) return <Navigate to="/" replace/>;
    if (pathname.startsWith('/mission-planner') && !screenAccess.planner) return <Navigate to="/" replace/>;


    return (
        <Outlet/>
    )
};

export default ProtectedRouteLayout

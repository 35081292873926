import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';

import PictureIcon from '../../assets/img/icons/picture.svg';
import ZoomIcon from '../../assets/img/icons/zoom.svg';
import DragIcon from '../../assets/img/icons/drag.svg';
import PlusIcon from '../../assets/img/icons/plus.svg';
import MinusIcon from '../../assets/img/icons/minus.svg';
import Screen1Icon from '../../assets/img/icons/screen1.svg';
import Screen2Icon from '../../assets/img/icons/screen2.svg';
import Screen3Icon from '../../assets/img/icons/screen3.svg';
import EditIcon from '../../assets/img/icons/edit.svg';
import { format } from 'date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

export const LineChart = ({openModal, config, data}) => {
    const dataSourceColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];

  const chartData = {
    labels: data.map(item => item.updatedAt),
    // datasets: [
    //   {
    //     label: 'Data Source 1',
    //     data: [0.9, 1.2, 3, 2.5, 2.3, 3],
    //     fill: false,
    //     borderColor: '#50b6f0',
    //     backgroundColor: '#caddf5',
    //     borderWidth: 1,
    //     tension: 0.2
    //   },
    //   {
    //     label: 'Data Source 2',
    //     data: [1, 2.5, 2.7, 3, 2.6, 4],        
    //     fill: false,
    //     borderColor: '#2086b4',
    //     backgroundColor: '#d0e9f8',
    //     borderWidth: 1,
    //     tension: 0.2
    //   }
    //   ],
    datasets: Object.entries(config.config).map(([key, value], index) => ({
        label: value,
        data: data.map(item => item[key]),      
        fill: false,
        backgroundColor: dataSourceColors[index % dataSourceColors.length],
        borderColor: dataSourceColors[index % dataSourceColors.length],
        pointBackgroundColor: dataSourceColors[index % dataSourceColors.length],
        borderWidth: 1,
        tension: 0.2
    })),
    backgroundColor: dataSourceColors
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time', // Изменяем на 'time' для корректного отображения временных меток
        time: {
        //   unit: 'auto', // Настройка единицы времени (можно менять на 'hour', 'day' и т.д.)
        },
      },
    },
    plugins: {
      legend: {
        position: 'right',
        align: 'start',
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          fillStyle: '#caddf5',
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
			mode: 'x',
		//   onZoom: ({ chart }) => {
        //     const minTimestamp = chart.scales.x.min;
        //     const maxTimestamp = chart.scales.x.max;
        //     const range = maxTimestamp - minTimestamp;

        //     // Определяем единицу измерения на основе диапазона
        //     let unit;
        //     if (range < 1000 * 60 * 60) {
        //       unit = 'minute'; // до 1 часа
        //     } else if (range < 1000 * 60 * 60 * 24) {
        //       unit = 'hour'; // до 1 дня
        //     } else if (range < 1000 * 60 * 60 * 24 * 7) {
        //       unit = 'day'; // до 1 недели
        //     } else if (range < 1000 * 60 * 60 * 24 * 30) {
        //       unit = 'week'; // до 1 месяца
        //     } else {
        //       unit = 'month'; // более 1 месяца
        //     }

        //     chart.options.scales.x.time.unit = unit;
        //     chart.update();
        //   },
        },
      },
    },
  };


  return (
        <div className="widget-inner h-56 flex flex-col w-full">
            <div className="text-xs font-semibold tracking-wide text-neutral-950 max-md:max-w-full dark:text-white flex">
                <span className="widget-title flex items-center">
                    <span>{config.name}</span>
                    <button className='graph-tools ml-2' onClick={() => openModal('selectGraph')}>
                        <img src={EditIcon} className="dark-bright size-6" />
                    </button>
                </span>
                <ul className="graph-tools flex justify-end flex-1 gap-1">
                    <li>
                        <button>
                            <img src={PictureIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={ZoomIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={DragIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={PlusIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={MinusIcon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={Screen1Icon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={Screen2Icon} className="dark-bright size-6" />
                        </button>
                    </li>
                    <li>
                        <button>
                            <img src={Screen3Icon} className="dark-bright size-6" />
                        </button>
                    </li>
                </ul>
            </div>
            <div className="h-44 w-full mt-1">
                <Line data={chartData} options={options} />
            </div>
        </div>
  );
};

 
import axios from "axios";
import { apiConfig } from "./config";
import { auth } from "./parts/auth";
import { users } from "./parts/users";
import {fleetApi} from "./parts/fleetApi";
import { filtersApi } from "./parts/filtersApi";
import { chartsApi } from "./parts/chartsApi";
import { widgetsApi } from "./parts/widgetsApi";
import {dashboard} from "./parts/dashboard";

export const apiInstance = axios.create(apiConfig);
apiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

const api = {
    auth: auth(apiInstance),
    users: users(apiInstance),
    dashboard: dashboard(apiInstance),
    fleet: fleetApi(apiInstance),
    filters: filtersApi(apiInstance),
    charts: chartsApi(apiInstance),
    widgets: widgetsApi(apiInstance)
}

export default api;

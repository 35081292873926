

export const fleetApi = (instance) => ({



    async getAllMissions() {
        const { data } = await instance.get(`/missions?no_limit=true`);
        return data;
    },
    async getMissionById(missionId) {
        const { data } = await instance.get(`/missions/${missionId}`);
        return data;
    },

    async createMission(payload) {
        // {
        //     "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        //     "start": "2024-04-22T06:50:21.138Z",
        //     "end": "2024-04-23T06:50:39.526Z",
        //     "status": "draft",
        //     "vesselId": 1
        // }
        const { data } = await instance.post(`/missions`, payload);
        return data;
    },

    async updateMission(payload, id) {
        const { data } = await instance.put(`/missions/${id}?attachWaypoints=1`, payload);
        return data;
    },

    async deleteMission(id) {
        const { data } = await instance.delete(`/missions/${id}`);
        return data;
    },

    async cloneMission(id) {
        const { data } = await instance.post(`/missions/clone/${id}`);
        return data;
    },

    async cloneMissionWithName(payload) {
        const { data } = await instance.post(`/missions/clone/${payload.id}`, { name: payload.name });
        return data;
    },
    async pushMission(id) {
        // test
        // const { data } = await instance.get(`/waypoints/push/${id}?isLockPush=1`);
        // prod
        const { data } = await instance.get(`/waypoints/push/${id}`);
        return data;
    },

    async getAllVessel() {
        const { data } = await instance.get(`/vessel?no_limit=true&sort_as=desc&sort_by=id`);
        return data;
    },

    async getOneVessel(id) {
        const { data } = await instance.get(`/vessel/${id}`);
        return data;
    },
    async getOneHistoryVessel(id) {
        const { data } = await instance.get(`/vessel/history/${id}?no_limit=true`);
        return data;
    },
    async getLastHistory(id) {
        const {data} = await instance.get(`/vessel/history/${id}?limit=1`);
        return data;
    },

    async createVessel(payload) {
        // {
        //     "imei": 123213,
        //     "name": "Vessel 1",
        //     "serial": 12334,
        //     "fleetId": 1
        // }
        const { data } = await instance.post(`/vessel`, payload);
        return data;
    },

    async updateVessel(payload, id) {
        const { data } = await instance.put(`/vessel/${id}`, payload);
        return data;
    },

    async deleteVessel(id) {
        const { data } = await instance.delete(`/vessel/${id}`);
        return data;
    },

    async getAllFleets() {
        const { data } = await instance.get(`/fleets`);
        return data;
    },

    async getOneFleet(id) {
        const { data } = await instance.get(`/fleets/${id}`);
        return data;
    },

    async createFleet(payload) {
        // {
        //     "title": "Fleet 112312312312"
        // }
        const { data } = await instance.post(`/fleets`, payload);
        return data;
    },

    async updateFleet(payload, id) {
        const { data } = await instance.put(`/fleets/${id}`, payload);
        return data;
    },

    async deleteFleet(id) {
        const { data } = await instance.delete(`/fleets/${id}`);
        return data;
    },

    async getWayPoints(missionId) {
        const { data } = await instance.get(`/waypoints/${missionId}`);
        return data;
    },

    async createWayPoints({ body, missionId }) {
        // {
        //     "waypoints": [{
        //     "lat": 50.64858972170902,
        //     "lon": -0.8267211914062501,
        //     "title": "Test",
        //     "missionId": 1
        // }]
        // }
        const { data } = await instance.post(`/waypoints/${missionId}`, body);
        return data;
    },

    async rewriteAllWayPoints({ body, missionId }) {
        // {
        //     "waypoints": [{
        //     "lat": 50.64858972170902,
        //     "lon": -0.8267211914062501,
        //     "title": "Test",
        //     "missionId": 1
        // }]
        // }
        const { data } = await instance.post(`/waypoints/rewrite-all-waypoints/${missionId}`, body);
        return data;
    },

    async updateWayPoints({ body, missionId }) {
        // {
        //     "waypoints": [{
        //         id: 3,

        //     "lat": 50.64858972170902,
        //     "lon": -0.8267211914062501,
        //     "title": "Test",
        //     "missionId": 1
        // }]
        // }
        const { data } = await instance.post(`/waypoints/${missionId}`, body);
        return data;
    },

    async deleteWayPoint(pointId) {
        const { data } = await instance.delete(`/waypoints/${pointId}`)
        return data;
    }





})

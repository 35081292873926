import React from 'react'
import Loader from './Loader'

const PageLoader = () => {
    return (
        <div className='absolute z-[99999] inset-0 flex flex-col items-center justify-center bg-opacity-30 bg-white backdrop-blur-lg'>
            <Loader width="60" height="60" />
        </div>
    )
}

export default PageLoader
import React, { useContext, useEffect, useState } from 'react';
import mapboxgl from "mapbox-gl";
import { ThemeContext } from '../App';
import DragIcon from '../assets/img/icons/drag.svg';
import "mapbox-gl/dist/mapbox-gl.css";
import { toast } from 'react-toastify';

export function groupByWaypoint(data) {
    const groupedData = {};

    data.forEach(item => {
        // const key = item.msg_format === 1
        //     ? `${item.target_waypoint_lat}-${item.target_waypoint_long}`
        //     : `${item.target_waypoint_latitude}-${item.target_waypoint_longitude}`;
        const key = "wp-" + item.active_waypoint_index
        if (!groupedData[key]) {
            groupedData[key] = [];
        }
        groupedData[key].push(item.msg_format === 1 ? [item.longditude, item.latitude] : [item.longitude, item.latitude]);
    });
    return Object.values(groupedData);
}

const ExplorerMap = ({ pointClickCallback, historyPoints, closestDateObject, minHeight="none" }) => {



    const { theme, changeTheme } = useContext(ThemeContext)
    const [map, setMap] = useState(null)

    const customClick = (e) => {
        if (Object.values(e.target.classList).includes('photo-point')) {
            pointClickCallback && pointClickCallback()
        }
    }

    useEffect(() => {
        document.addEventListener('click', customClick)

        return () => document.removeEventListener('click', customClick)
    }, [])

    const [hasDraw, setHasDraw] = useState(false)
    const [markers, setMarkers] = useState([])
    const [vessel, setVessel] = useState(null)
    const [styleLoaded, setStyleLoaded] = useState(false)
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = () => {
        setIsFullscreen((prevFullscreen) => !prevFullscreen);

        setTimeout(() => {
            map?.resize();
        }, 300);
    };


    useEffect(() => {
        markers.forEach((marker) => marker.remove())
        setMarkers([])
        if (vessel) {
            vessel.remove()
            setVessel(null)
        }
        const uniquePoints = Array.from(new Set(historyPoints.map(item => {
            if (item.target_waypoint_long) return item.target_waypoint_lat + ',' + item.target_waypoint_long
            return item?.target_waypoint_latitude + ',' + item?.target_waypoint_longitude
        }))).map(coordString => coordString.split(',').map(Number));

        const points = uniquePoints.map((item, index) => ({
            coordinates: item,
            // name: 'Point A',
            hidden: false,
            count: false,
            number: 0,
            numberOn: index + 1,
        }))
        // const marker = {
        //     coordinates: [historyPoints?.[historyPoints.length -1]?.longditude || historyPoints?.[historyPoints.length -1]?.longitude, historyPoints?.[historyPoints.length -1]?.latitude],
        //     target_heading: historyPoints?.[historyPoints.length -1]?.target_heading
        // }
        const marker = {
            coordinates: [closestDateObject?.longditude || closestDateObject?.longitude, closestDateObject?.latitude],
            target_heading: closestDateObject?.target_heading
        }

        const groupedData = groupByWaypoint(historyPoints);

        if (!map || !points.length || !marker || !styleLoaded) return
        // setHasDraw(true)

        const pointsArr = points.map(point => {

            const a = document.createElement('div');
            const attrValue = point.numberOn;
            const isEqual = point.coordinates[0] === closestDateObject?.target_waypoint_latitude && point.coordinates[1] === closestDateObject?.target_waypoint_longitude
            a.classList.add('map-point');
            if (point.hidden) {
                a.classList.add('map-point-hidden');
            }
            if (isEqual) {
                a.classList.add('current-point')
            }
            a.setAttribute('data-number', attrValue);
            const [lat, lng] = point.coordinates;

            if (isNaN(parseFloat(lat)) || lat < -90 || lat > 90) {
                toast.error(`Latitude value: must be between -90 and 90. Now is: ${lat}`);
                return null;
            }
            if (isNaN(parseFloat(lng)) || lng < -180 || lng > 180) {
                toast.error(`Longitude value: must be between -180 and 180. Now is: ${lng}`);
                return null;
            }
            const marker = new mapboxgl.Marker({ element: a })
                .setLngLat([lng, lat])
                .addTo(map);

            a.classList.add(`map-point-${attrValue}`);
            marker.getElement().style.display = 'block'
            return { marker, point };
        }).filter(Boolean)
        setMarkers(pointsArr.map(item => item.marker))


        const fullscreenControl = document.createElement('button');
        fullscreenControl.style.cssText = 'position: absolute; bottom: 7px; right: 10px; z-index: 20; padding: 5px 5px; background: white; border-radius: 5px; cursor: pointer; opacity: unset;';
        const img = document.createElement('img');
        img.src = DragIcon;
        img.alt = isFullscreen ? 'Exit Fullscreen' : 'Fullscreen';
        img.className = 'dark-bright size-6';
        fullscreenControl.appendChild(img);

        fullscreenControl.onclick = toggleFullscreen;
        document.getElementById('map-container').appendChild(fullscreenControl);




        const targets = [
            marker

        ];
        const targetsArr = targets.map(point => {
            const a = document.createElement('div')

            a.classList.add('map-ship-marker')

            const [lng, lat] = point.coordinates;

            if (isNaN(parseFloat(lat)) || lat < -90 || lat > 90) {
                toast.error(`Latitude value: must be between -90 and 90. Now is: ${lat}`);
                return null;
            }
            if (isNaN(parseFloat(lng)) || lng < -180 || lng > 180) {
                toast.error(`Longitude value: must be between -180 and 180. Now is: ${lng}`);
                return null;
            }
            const marker = new mapboxgl.Marker({ element: a })
                .setLngLat(point.coordinates)
                .setRotation(point.target_heading)
                .addTo(map);

            map.flyTo({
                center: point.coordinates, // Target coordinates
                speed: 0.3, // Easing speed (0 to 1)
                curve: 1, // Easing curve (0 to 1)
                essential: true, // Set to true for essential animations (avoids interruptions)
            });

            console.log("point.coordinates", point.coordinates);
            
            // map.setZoom(15)
            setVessel(marker)


            marker.getElement().style.display = 'block'
            return { marker, point };


        });




        // const lineGeojson = {
        //     type: 'Feature',
        //     properties: {},
        //     geometry: {
        //         type: 'LineString',
        //         coordinates: []
        //     }
        // };
        const lineGeojsonBefore = {
            type: 'FeatureCollection',

            features: groupedData.map((item, index, array) => {
                const coordinates = [...item];
                const next = array[index + 1]
                if (next) {
                    coordinates.push(next[0])
                }
                return ({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': coordinates
                    },
                    'properties': {
                        'colorValue': (index) * (60 / (groupedData.length - 1))
                    }
                })
            })
            // properties: {
            //     colorValue: 50
            // },
            // geometry: {
            //     type: 'LineString',
            //     coordinates: allCoords
            // }
        };

        if (map.getLayer('line-before')) {
            map.removeLayer('line-before');
        }
        if (map.getSource('line-source-before')) {
            map.removeSource('line-source-before');
        }

        // map.addSource('line-source', {
        //     type: 'geojson',
        //     data: lineGeojson
        // });

        map.addSource('line-source-before', {
            type: 'geojson',
            data: lineGeojsonBefore
        });

        map.setFeatureState({
            source: 'line-source-before',
            id: 'line-before'
        }, {
            dark: theme ? true : false
        });


        // map.addLayer({
        //     id: 'line',
        //     type: 'line',
        //     source: 'line-source',
        //     layout: {
        //         'line-join': 'round',
        //         'line-cap': 'round',
        //         'visibility': 'none'
        //     },
        //     paint: {
        //         'line-color': '#3477b5',
        //         'line-width': 2,
        //         'line-dasharray': [3, 3]
        //     }
        // });

        map.addLayer({
            id: 'line-before',
            type: 'line',
            source: 'line-source-before',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
                'visibility': 'visible'
            },
            paint: {
                'line-color': ['interpolate', ['linear'], ['get', 'colorValue'],
                    0, '#FF0000',
                    10, '#FF7F00',
                    20, '#FFFF00',
                    30, '#00FF00',
                    40, '#0000FF',
                    50, '#4B0082',
                    60, '#9400D3'
                ],
                'line-width': 2,
                // 'line-dasharray': [1, 3]
            }

        });



        // const handleZoom = () => {
        //     const zoomLevel = map.getZoom();

        //     pointsArr.forEach(({ marker, point }) => {
        //         marker.getElement().style.display = 'block';
        //     });
        //     targetsArr.forEach(({ marker, point }) => {
        //         marker.getElement().style.display = 'block';
        //     });

        //     // map.setLayoutProperty('line', 'visibility', 'visible');
        //     map.setLayoutProperty('line-before', 'visibility', 'visible');
        // }
        // setTimeout(() => handleZoom(), 100)

        // map.on('zoom', handleZoom);


    }, [map, hasDraw, historyPoints, styleLoaded, closestDateObject])

    useEffect(() => {

        setMap(null)
        setStyleLoaded(false)

        mapboxgl.accessToken = process.env.REACT_APP_MAPBOXKEY;

        const currentMap = new mapboxgl.Map({
            container: 'map-container',
            // style: theme ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/streets-v11',
            style: theme ? 'mapbox://styles/maximokolzin/clth2mbte018x01pj2usp8uxu' : 'mapbox://styles/maximokolzin/clth258fl00br01nr4uhb69vt',
            center: [closestDateObject?.longditude || closestDateObject?.longitude, closestDateObject?.latitude] || [-1.875715476596882, 50.61610028107245],
            zoom: 10,
        });



        const clusterData = [].map(it => {
            return it.map(el => {
                if (!el.count) return false

                return {
                    "geometry": {
                        "type": "Point",
                        "coordinates": [...el.coordinates, 0]
                    }
                }
            }).filter(el => !!el)


        })

        const clast = clusterData.flatMap(el => el)


        currentMap.on('style.load', () => {
            setStyleLoaded(true);
            const control = new mapboxgl.NavigationControl({
                showCompass: false
            });
            currentMap.addControl(control);
            currentMap.addSource('earthquakes', {
                type: 'geojson',


                data: { features: clast },
                cluster: true,
                clusterMaxZoom: 20,
                clusterRadius: 50,
            });


            // currentMap.addLayer({
            //     id: 'clusters',
            //     type: 'circle',
            //     source: 'earthquakes',
            //     filter: ['has', 'point_count'],
            //     paint: {
            //         'circle-color': [
            //             'step',
            //             ['get', 'point_count'],
            //             'red',
            //             100,
            //             'red',
            //             750,
            //             'red'
            //         ],
            //         'circle-radius': [
            //             'step',
            //             ['get', 'point_count'],
            //             20,
            //             100,
            //             30,
            //             750,
            //             20,
            //         ]
            //     }
            // });

            currentMap.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'earthquakes',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#e42320',
                        100,
                        '#e42320',
                        750,
                        '#e42320'
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        20,
                        100,
                        30,
                        750,
                        20,
                    ],
                    'circle-stroke-width': 10,
                    'circle-stroke-opacity': 0.5,
                    'circle-stroke-color': '#e42320',
                }
            });



            currentMap.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'earthquakes',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,

                },
                paint: {
                    'text-color': 'white',
                }
            });

        })


        currentMap.on('click', 'clusters', (e) => {
            const features = currentMap.queryRenderedFeatures(e.point, {
                layers: ['clusters']
            });
            const clusterId = features[0].properties.cluster_id;
            currentMap.getSource('earthquakes').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    currentMap.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom + 1
                    });
                }
            );
        });


        currentMap.on('mouseenter', 'clusters', () => {
            currentMap.getCanvas().style.cursor = 'pointer';
        });
        currentMap.on('mouseleave', 'clusters', () => {
            currentMap.getCanvas().style.cursor = '';
        });


        currentMap.on('click', function (e) {
            var coordinates = e.lngLat;
            console.log('point:', [coordinates.lng, coordinates.lat]);
        });

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        // Добавление события для кластеров
        currentMap.on('mouseenter', 'clusters', (e) => {
            currentMap.getCanvas().style.cursor = 'pointer';

            const coordinates = e.features[0].geometry.coordinates.slice();
            const count = e.features[0].properties.point_count;
            const description = `Vessel 1 <br/> Vessel 2 <br/> Vessel 3`;

            // Установка контента попапа и его координат
            popup.setLngLat(coordinates)
                .setHTML(description)
                .addTo(currentMap);
        });

        // Удаление попапа при уходе с кластера
        currentMap.on('mouseleave', 'clusters', () => {
            currentMap.getCanvas().style.cursor = '';
            popup.remove();
        });



        setMap(currentMap)



        return () => {
            currentMap.remove()
            currentMap.off('mouseenter', 'clusters');
            currentMap.off('mouseleave', 'clusters');
            popup.remove();
        };
    }, [theme]);

    return (
        // <div id="map-container" style={{ width: '100%', height: '100%' }}></div>


        <div
            id="map-container"
            style={{
                position: isFullscreen ? 'fixed' : 'relative',
                top: isFullscreen ? 0 : 'auto',
                left: isFullscreen ? 0 : 'auto',
                width: isFullscreen ? '100vw' : '100%',
                height: isFullscreen ? '100vh' : '100%',
                zIndex: isFullscreen ? 1000 : 'auto',
                transition: 'all 0.3s',
                minHeight
            }}
        ></div>

    );
};

export default ExplorerMap;

import { toast } from "react-toastify";
import indicatorIcon from "../../assets/img/icons/indicator.svg"

const L = window.L;


export class Vessel {
    _map = window.customWindy.map;
    _marker = null;
    constructor({
        lat, lng, lon,
        rotationAngle = 0,
        rotationOrigin = "center center"
    }) {
        this.lat = lat
        this.lng = lng || lon;
        this.rotationAngle = rotationAngle
        this.rotationOrigin = rotationOrigin
        this.init()
    }

    createIcon() {
        return L.icon({
            iconUrl: indicatorIcon,
            iconSize: [36, 49],
            iconAnchor: [18, 25],
        });
    }

    createMarker() {
        this._marker = L.marker([this.lat, this.lng], {
            icon: this.createIcon(),
            rotationAngle: this.rotationAngle,
            rotationOrigin: this.rotationOrigin,
        }).addTo(this._map);

    }
    get _latlng() {
        return { lat: this.lat, lng: this.lng }
    }
    setLatLng({ lat, lng }) {
        if (this._isValidLatLng(lat, lng)) {
            this.lat = lat;
            this.lng = lng;
            this._marker.setLatLng(this._latlng)
        }
    }
    setRotationAngle(angle) {
        this.rotationAngle = angle
        this._marker.setRotationAngle(angle)
    }
    _isValidLatLng(lat, lng) {
        const validLat = !isNaN(parseFloat(lat)) && lat >= -90 && lat <= 90;
        const validLng = !isNaN(parseFloat(lng)) && lng >= -180 && lng <= 180;

        if (!validLat) toast.error(`Waypoint ${this.index + 1}: Latitude must be between -90 and 90. Current value is ${lat}`);
        if (!validLng) toast.error(`Waypoint ${this.index + 1}: Longitude must be between -180 and 180.Current value is ${lng}`);

        return validLat && validLng;
    }
    destroy() {
        this._marker.remove()
    }
    init() {
        this.createMarker()
    }
}
import classNames from 'classnames';
import React from 'react';

import { ReactComponent as SearchIcon } from "../../assets/img/search.svg";
import { ReactComponent as SailIcon } from "../../assets/img/icons/sail.svg";

const iconTypes = {
    search: SearchIcon,
    sail: SailIcon
}

const InputBox = ({ 
    iconType, className, wrapperClassName,...props
}) => {
    const Icon = iconTypes[iconType];
    return <div className={classNames('h-[32px] w-full rounded-[4px] border border-[#0B0B0B40] py-[4px] px-[8px] flex items-center gap-[4px]', wrapperClassName)}>
        <input
            className={classNames('bg-inherit block w-full h-[16px] text-[12px]', className)}
            {...props}
        />
        {Icon && <Icon className='min-w-[24px] icon-black-50'/>}
    </div>
};


export default InputBox;
import { createSlice } from '@reduxjs/toolkit';
import {
    fetchWidgetById,
    fetchLayoutsData,
    fetchLayoutById, fetchWidgetsData
} from '../thunks/dashboardThunks';

const initialState = {
    widgets: [],
    layouts: [],
    selectedWidget: null,
    selectedLayout: null,
    loading: false,
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(fetchWidgetsData.fulfilled, (state, action) => {
            state.widgets = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchWidgetsData.pending, (state) => {
            state.loading = true;

        });
        builder.addCase(fetchWidgetsData.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(fetchWidgetById.fulfilled, (state, action) => {
            state.selectedWidget = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchWidgetById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchWidgetById.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(fetchLayoutsData.fulfilled, (state, action) => {
            state.layouts = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchLayoutsData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchLayoutsData.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(fetchLayoutById.fulfilled, (state, action) => {
            console.log("action.payload", action.payload)

            state.selectedLayout = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchLayoutById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchLayoutById.rejected, (state, action) => {
            state.loading = false;
        });
    }
});

export const {
    setSelectedWidget,
    setSelectedLayout,
    setLoading,
} = dashboardSlice.actions;

export const getWidgets = (state) => state.dashboard.widgets;
export const getLayouts = (state) => state.dashboard.layouts;
export const getSelectedWidget = (state) => state.dashboard.selectedWidget;
export default dashboardSlice.reducer;

import { format, formatDistance } from 'date-fns';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from "../assets/img/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../assets/img/icons/clock.svg";
import { ReactComponent as PauseIcon } from "../assets/img/icons/pause.svg";
import { ReactComponent as PlayIcon } from "../assets/img/icons/play.svg";
import { ReactComponent as RewindIcon } from "../assets/img/icons/rewind.svg";
import { ReactComponent as StopIcon } from "../assets/img/icons/stop.svg";
import InputBox from './base/InputBox';
import PanelButton from './base/PanelButton';
import { Slider } from './Slider';

const METERS_TO_NAUTICAL_MILES = 1 / 1852;

const isValidDate = (date) => {
    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime());
};
Number.prototype.toNauticalMiles = function () {
    return this * METERS_TO_NAUTICAL_MILES;
};

function calculateAverage(values) {
    const sum = values.reduce((acc, curr) => acc + curr, 0);
    return sum / values.length;
}
const L = window.L;

const TimelineSimulation = ({
    currentMission, wayPoints, windy, vessel, onPlay
}) => {
    const [allWayPoints, setAllWayPoints] = useState([])
    const [sliderPercentage, setSliderPercentage] = useState(0)

    const [startDateProp, setStartDateProp] = useState(new Date(currentMission?.start || new Date()));
    const [endDateProp, setEndDateProp] = useState(new Date(currentMission?.end || null));

    const [windData, setWindData] = useState(null)

    const [isSimulating, setIsSimulating] = useState(false);

    const [knots, setKnots] = useState(17);

    const [playerStatus, setPlayerStatus] = useState("stop");

    const [intervalStarted, setIntervalStarted] = useState(false);
    const [playInterval, setPlayInterval] = useState(false);

    const [wayLength, setWayLength] = useState(1);
    const [avgVesselSpeed, setAvgVesselSpeed] = useState(0);
    const [vesselHeadings, setVesselHeadings] = useState([]);

    const [allWayDuration, setAllWayDuration] = useState(0)

    const getPlayLine = () => [
        {
            date: startDateProp.toLocaleDateString(),
            day: String(startDateProp.toTimeString()).slice(0, 5)
        },

        {
            date: endDateProp.toLocaleDateString(),
            day: String(endDateProp.toTimeString()).slice(0, 5)
        }
    ];
    const [startDate, setStartDate] = useState(null);

    const getDaysArray = (start, end) => {
        const result = [];
        const startDate = new Date(start);
        const endDate = new Date(end);

        while (startDate <= endDate) {
            result.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }
        return result;
    };

    // Generate timeline once based on start and end dates
    const daysArray = getDaysArray(startDateProp, endDateProp);
    const totalDays = daysArray.length;
    const dayPercentageStep = 100 / totalDays;

    const timeline = daysArray.map((dateItem, index) => {
        const itemPrevPercentage = Math.floor(dayPercentageStep * index);
        const itemPercentage = Math.floor(dayPercentageStep * (index + 1));
        const hourPercentageStep = (itemPercentage - itemPrevPercentage) / 24;

        return {
            index,
            date: dateItem.toDateString(),
            day: new Date().toLocaleDateString() === dateItem.toLocaleDateString() ? `(Today)` : `(${dateItem.toLocaleDateString('en', { weekday: 'long' })})`,
            percentage: itemPercentage,
            times: Array.from({ length: 24 }, (_, hourIndex) => ({
                time: `${hourIndex.toString().padStart(2, '0')}:00`,
                percent: Math.ceil(itemPrevPercentage + hourIndex * hourPercentageStep)
            }))
        };
    });

    const dateOnChange = (date) => {
        const selectedDate = new Date(date).toDateString();
        const foundDateData = timeline.find(item => item.date === selectedDate);

        setStartDate(date);
        setSliderPercentage(foundDateData ? foundDateData.percentage : 0);
    };

    const timeOnChange = (date) => {
        const selectedDate = new Date(date).toDateString();
        const selectedTime = new Date(date).toTimeString().slice(0, 5);

        const foundDateData = timeline.find(item => item.date === selectedDate);
        const foundTimeData = foundDateData?.times.find(time => time.time === selectedTime);

        setStartDate(date);
        setSliderPercentage(foundTimeData?.percent || 0);
    };




    const simulateStop = () => {
        clearInterval(playInterval);
        setPlayerStatus("stop");
        setIntervalStarted(false);
        document.querySelector(':root').style.setProperty('--player-transition', '0s');
    }

    function calculateVesselSpeed(vesselSpeed, vesselHeading, windData) {
        if (!windData) return vesselSpeed;

        const { dir: windDirection, wind: windSpeed } = windData;

        const vesselHeadingRad = vesselHeading * (Math.PI / 180);
        const windDirectionRad = windDirection * (Math.PI / 180);

        const vesselX = vesselSpeed * Math.sin(vesselHeadingRad);
        const vesselY = vesselSpeed * Math.cos(vesselHeadingRad);
        const windX = windSpeed * Math.sin(windDirectionRad);
        const windY = windSpeed * Math.cos(windDirectionRad);

        const effectiveX = vesselX + windX;
        const effectiveY = vesselY + windY;

        const effectiveSpeed = Math.sqrt(effectiveX * effectiveX + effectiveY * effectiveY);

        return effectiveSpeed;
    }
    useEffect(() => {
        if (wayPoints && windy) {
            const map = window.customWindy.map;

            const latlngs = wayPoints.map((point) => L.latLng([point.lat, point.lng]));

            const lengths = L.accumulatedLengths(latlngs);
            const totalLength = lengths[lengths.length - 1];

            console.log('Total Length in meters:', totalLength);

            setWayLength(totalLength); // in meters
            
            let interval = 1000;
            if (totalLength > 50000) {
                interval = 1000;
            } else {
                interval = 500;
            }
            const totalPoints = Math.floor(totalLength / interval);

            const ratios = [];
            for (let i = 0; i <= totalPoints; i++) {
                const ratio = (i * interval) / totalLength;
                ratios.push(ratio);
            }

            const points = ratios.map((ratio) =>
                L.interpolateOnLine(map, latlngs, ratio)
            );

            const headings = points.map((point, index, array) => {
                if (!array[index - 1]) return undefined;
                return L.angle(map, point.latLng, array[index - 1].latLng);
            }).filter(Boolean);


            setVesselHeadings(headings)
            setAllWayPoints(points?.map((item) => item?.latLng));

            console.log("total trip: ", points?.map((item) => item?.latLng));

        }
    }, [wayPoints, windy]);


    const vesselSpeed = useMemo(() => calculateVesselSpeed((1.852 * knots), windData), [windData, knots])
    // const vesselSpeed = knots * 1.852

    // const getTimerInterval = () => {
    //     const simulationTime = 10 * 1000;

    //     const wayLengthNm = wayLength / 1852;

    //     const tripDurationSeconds = wayLengthNm / vesselSpeed * 1000;
    //     console.log(tripDurationSeconds);

    //     return (simulationTime / tripDurationSeconds).toFixed(1) * 1000;
    // }
    const getTimerInterval = () => {
        const intervalTime = 1000 / vesselSpeed
        return intervalTime * 10;
    };




    // const timerInterval = ((wayLength / vesselSpeed) / 60).toFixed(1) * 1000;
    const timerInterval = useMemo(getTimerInterval, [vesselSpeed, wayLength]);

    useEffect(() => {

        const speeds = vesselHeadings.map(heading => calculateVesselSpeed(vesselSpeed, heading, windData))

        setAvgVesselSpeed(calculateAverage(speeds))

        const wayLengthNm = wayLength / 1852;

        const vesselSpeedKnots = vesselSpeed / 1.85;

        const tripDurationMs = (wayLengthNm / vesselSpeedKnots) * 60 * 60 * 1000;

        const arrivalTimestamp = new Date(Date.now() + tripDurationMs);

        setEndDateProp(new Date(arrivalTimestamp));

    }, [vesselSpeed, wayLength]);


    useEffect(() => {

        if (playerStatus === "play" && !intervalStarted) {
            document.querySelector(':root').style.setProperty('--player-transition', `${timerInterval / 1000}s`);

            setPlayInterval(setInterval(() => {
                setIntervalStarted(true);
                setSliderPercentage((prev) => {
                    if (prev < 100) {
                        // console.log("progress:", prev + "%");
                        return prev + 1;
                    }
                });

            }, timerInterval));
        } else if (intervalStarted) {
            simulateStop();
        }
    }, [playerStatus]);

    useEffect(() => {
        if (sliderPercentage > 99 && playInterval && intervalStarted) {
            simulateStop();
        }
    }, [sliderPercentage]);

    useEffect(() => {
        simulateStop();

        if (!wayPoints.length) {
            setIsSimulating(false)
        }
    }, [wayPoints])


    useEffect(() => {
        if (vessel && allWayPoints?.length) {
            const map = window.customWindy.map;

            const maxIndex = allWayPoints.length - 1;
            let percentageIndex = Math.min(
                Math.floor((sliderPercentage / 100) * maxIndex),
                maxIndex
            );
            const targetPoint = allWayPoints[percentageIndex];

            if (targetPoint?.lat && targetPoint?.lng) {
                const newLatLng = new L.LatLng(targetPoint.lat, targetPoint.lng);

                const newAngle = L.angle(map, vessel._latlng, newLatLng);

                vessel.setRotationAngle(newAngle);
                vessel.setLatLng(newLatLng);
            }
        }
    }, [vessel, sliderPercentage, allWayPoints]);

    const getWindDataForPoints = () => {
        const { map, store, utils, picker } = window.customWindy;
        const windDataPromises = wayPoints.map(async (coord) => {
            const { lat, lng } = coord._latlng;

            setTimeout(() => picker.open({ lat, lon: lng }), 100)
            setTimeout(picker.close, 150)
            return new Promise((resolve) => {
                picker.once('pickerOpened', (data) => {
                    const [u, v] = data.values;
                    const windData = utils.wind2obj([u, v]);
                    resolve(windData);
                    picker.close()
                });
            });
        });

        return Promise.all(windDataPromises);
    };

    const getWindInfo = async () => {
        const windDatas = await getWindDataForPoints()
        const avgWind = windDatas.reduce(
            (acc, { dir, wind }) => {
                const dirRad = (dir * Math.PI) / 180;
                acc.x += wind * Math.cos(dirRad);
                acc.y += wind * Math.sin(dirRad);
                acc.totalSpeed += wind;
                acc.count += 1;
                return acc;
            },
            { x: 0, y: 0, totalSpeed: 0, count: 0 }
        );

        const avgDirection = Math.atan2(avgWind.y, avgWind.x);
        const avgWindSpeed = avgWind.totalSpeed / avgWind.count;

        const normalizedAvgDirection = ((avgDirection * 180) / Math.PI + 360) % 360;

        const result = {
            dir: Math.round(normalizedAvgDirection),
            wind: Math.round(avgWindSpeed)
        };
        setWindData(result)
    }
    useEffect(() => {
        if (!wayPoints.length || !window.customWindy) return;
        getWindInfo()
    }, [wayPoints, windy])


    // useEffect(() => {
    //     if (window.customWindy && vessel) {
    //         const { picker, utils } = window.customWindy;

    //         if (!picker) {
    //             return;
    //         }

    //         const handlePickerOpened = (data) => {
    //             const [u, v] = data.values;
    //             if (u !== undefined && v !== undefined) {
    //                 const windData = utils.wind2obj([u, v]);
    //                 setWindData(windData);
    //             }
    //             picker.close();
    //         };

    //         picker.on("pickerOpened", handlePickerOpened);

    //         return () => {
    //             picker.off("pickerOpened", handlePickerOpened);
    //         };
    //     }
    // }, [window.customWindy, vessel]);



    const playLine = useMemo(getPlayLine, [startDateProp, endDateProp])


    const handlePlay = async () => {
        if (sliderPercentage > 99) {
            setSliderPercentage(0)
        }
        onPlay && onPlay()
        // const { picker, utils } = window.customWindy;
        // const { lat, lng } = vessel._latlng;
        // picker.open({ lat, lon: lng });

        playerStatus !== "play"
            ? setPlayerStatus("play")
            : setPlayerStatus("pause");

    }

    if (!vessel || !wayPoints?.length) {
        return;
    }

    return isSimulating ?
        <div className='h-[132px] shadow-[0px_4px_8px_0px_#0000001A] border border-[#E4232040] rounded-[4px] p-[8px] bg-[#FBFBFB]' key={"isSimulating"}>
            <div className='rounded-[4px] border border-[#0B0B0B1A] bg-[#EFF2F4] p-[4px] flex items-center gap-[8px] min-h-[117px]'>
                <div className='flex flex-col justify-between gap-4 w-[158px]'>
                    <div className='flex items-center gap-[4px] justify-center'>
                        <p className='text-[10px] whitespace-nowrap'>Simulate at</p>
                        <InputBox
                            type="number"
                            wrapperClassName='w-[37px]'
                            placeholder='1'
                            value={knots}
                            onInput={(e) => {
                                setKnots(e.target.value);
                            }}
                            onFocus={() => {
                                setPlayerStatus("pause");
                            }}
                            onClick={() => {
                                setPlayerStatus("pause");
                            }}
                        />
                        <p className='text-[10px]'>Knot(s)</p>
                    </div>
                    <div className='flex justify-between items-center mt-[4px]'>
                        <button className="flex items-center justify-center"
                            onClick={(e) => {
                                setPlayerStatus("stop");
                                setSliderPercentage(0);
                                setIsSimulating(false)
                            }}
                        >
                            <RewindIcon className='icon-black-50' />
                        </button>
                        <button className="flex items-center justify-center"
                            onClick={handlePlay}
                        >
                            {
                                playerStatus !== "play"
                                    ? <PlayIcon className='icon-black-50' />
                                    : <PauseIcon className='icon-black-50' />
                            }
                        </button>
                        <button className="flex items-center justify-center"
                            onClick={(e) => {
                                setPlayerStatus("stop");
                                setSliderPercentage(0);
                            }}
                        >
                            <StopIcon className='icon-black-50' />
                        </button>
                    </div>
                </div>
                <div className='h-[100px] w-[1px] bg-[#0B0B0B1A]' />
                <div className='h-full flex-1'>
                    {
                        windData && (
                            <div className='flex items-center gap-4 justify-center'>
                                <div className='w-fit text-center'>
                                    <p className='text-[10px] whitespace-nowrap'>All distance</p>
                                    <span>{(wayLength * METERS_TO_NAUTICAL_MILES).toFixed(2)} nm</span>
                                </div>
                                {
                                    isValidDate(startDateProp) && isValidDate(endDateProp) && (
                                        <div className='w-fit text-center'>
                                            <p className='text-[10px] whitespace-nowrap'>Total duration</p>
                                            <span>{formatDistance(new Date(startDateProp), new Date(endDateProp))}</span>
                                        </div>
                                    )
                                }
                                <div className='w-fit text-center'>
                                    <p className='text-[10px] whitespace-nowrap'>Avg Vessel speed</p>
                                    <span>{(avgVesselSpeed / 1.852).toFixed()} Knot(s)</span>
                                </div>
                                <div className='w-fit text-center'>
                                    <p className='text-[10px] whitespace-nowrap'>Avg Wind speed</p>
                                    <span>{windData.wind.toFixed(2)} m/s</span>
                                </div>
                                <div className='w-fit text-center'>
                                    <p className='text-[10px] whitespace-nowrap'>Avg Wind dir</p>
                                    <span>{windData.dir}°</span>
                                </div>

                                <div className='w-fit text-center'>
                                    <p className='text-[10px] whitespace-nowrap'>Current Vessel dir</p>
                                    <span>{vessel.rotationAngle.toFixed()}°</span>
                                </div>
                                <div className='w-fit text-center'>
                                    <p className='text-[10px] whitespace-nowrap'>Current Vessel speed</p>
                                    <span>{(calculateVesselSpeed(vesselSpeed, vessel.rotationAngle, windData) / 1.852).toFixed()} Knot(s)</span>
                                </div>

                            </div>
                        )
                    }
                    <ul className='flex items-center justify-between'>
                        {playLine.map((item, index) => <li key={index} className='flex flex-col items-center gap-[4px]'>
                            <p className='text-[10px] leading-[13px] tracking-[0.04em] text-center text-[#0B0B0B80]'>
                                {item.date}<br />
                                {item.day}
                            </p>
                            <div className='h-[4px] w-[1px] bg-[#0B0B0B40]' />
                        </li>)}
                    </ul>
                    <div className={`px-[12px] py-[4px] ${playerStatus === "play" ? 'pointer-events-none' : ''}`}>
                        <Slider
                            start={sliderPercentage}
                            onChange={(e) => {
                                setSliderPercentage(+e);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div> :
        <div className='h-[132px] shadow-[0px_4px_8px_0px_#0000001A] border border-[#E4232040] rounded-[4px] p-[8px] bg-[#FBFBFB]' key={"isNotSimulating"}>
            <div className='rounded-[4px] border border-[#0B0B0B1A] bg-[#EFF2F4] p-[4px] flex items-center gap-[8px] min-h-[117px]'>
                <div className='flex flex-col'>
                    <ReactDatePicker
                        selected={startDate}
                        onChange={dateOnChange}
                        className="mb-[4px] z-[999] text-[12px] text-[#0B0B0B80] rounded-[4px] border border-solid border-[#0B0B0B40] h-[32px] w-[158px] !p-[8px]"
                        dateFormat={'dd/MM/yy'}
                        showIcon
                        calendarIconClassname="right-0 cursor-pointer"
                        icon={<CalendarIcon className='icon-black-50' />}
                        toggleCalendarOnIconClick
                        placeholderText="Date"
                        popperClassName="!z-[999]"
                        minDate={new Date(startDateProp)}
                        maxDate={new Date(endDateProp)}
                    />
                    <ReactDatePicker
                        selected={startDate}
                        onChange={timeOnChange}
                        className="mb-[8px] z-[999] text-[12px] text-[#0B0B0B80] rounded-[4px] border border-solid border-[#0B0B0B40] h-[32px] w-[158px] !p-[8px]"
                        dateFormat="h:mm aa"
                        showIcon
                        calendarIconClassname="right-0 cursor-pointer"
                        icon={<ClockIcon className='icon-black-50' />}
                        toggleCalendarOnIconClick
                        placeholderText="Time"
                        popperClassName="!z-[999]"
                        showTimeSelectOnly
                        showTimeSelect
                        minDate={new Date(startDateProp)}
                        maxDate={new Date(endDateProp)}
                        timeIntervals={60}
                    />
                    <PanelButton
                        Icon={PlayIcon}
                        onClick={() => {
                            setIsSimulating(true);
                            handlePlay();
                        }}
                    >
                        Simulate from here
                    </PanelButton>
                </div>
                <div className='h-[100px] w-[1px] bg-[#0B0B0B1A]' />
                <div className='h-full flex-1'>
                    <ul className='flex items-center justify-between'>
                        {(timeline.length > 10
                            ? [
                                timeline[0],
                                ...timeline.slice(1, -1).filter((_, index) => index % Math.ceil(timeline.length / 8) === 0), // Add intermediate items, keeping the total <= 10
                                timeline[timeline.length - 1]
                            ]
                            : timeline
                        ).map((item, index) => (
                            <li key={index} className='flex flex-col items-center gap-[4px]'>
                                <p className='text-[10px] leading-[13px] tracking-[0.04em] text-center text-[#0B0B0B80]'>
                                    {new Date(item.date).toLocaleDateString()} <br />
                                    {item.day}
                                    {/* <br /> {item.percentage} */}
                                </p>
                                <div className='h-[4px] w-[1px] bg-[#0B0B0B40]' />
                            </li>
                        ))}
                    </ul>

                    <div className='px-[12px] py-[4px]'>
                        <Slider
                            start={sliderPercentage}
                            onChange={(e) => {
                                setSliderPercentage(+e);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
};

export default TimelineSimulation;
const filtersBase = 'filters';

export const filtersApi = (instance) => ({
    async getAllFilters() {
        try {
            const { data } = await instance.get(filtersBase, {
                params: {
                    no_limit: true
                }
            });
            return data;
        } catch (e) {
            return e.response.data
        }
    },

    async createFilter(payload) {
        try {
            const { data } = await instance.post(filtersBase, payload);
            return data;
        } catch (e) {
            return e.response.data
        }
    },
    async getFilterCalendar(payload) {
        try {
            const { data } = await instance.get("missions/calendar", {
                params: payload
            });
            return data;
        } catch (e) {
            return e.response.data
        }
    },

    async updateFilter(payload, filterId) {
        try {
            const { data } = await instance.put(`${filtersBase}/${filterId}`, payload);
            return data;
        } catch (e) {
            return e.response.data
        }
    },

    async deleteFilter(filterId) {
        try {
            const { data } = await instance.delete(`${filtersBase}/${filterId}`);
            return data;
        } catch (e) {
            return e.response.data
        }
    },
});

import {convertToDegreesMinutes} from "../helpers/utils";


export const MapSideTable = ({tableData}) => {

    if(!tableData) return;
    const testData = Object.entries(tableData)



    // const data = [
    //     {
    //         title: 'Location Data',
    //         data: [
    //             {name: 'Longitude', val: convertToDegreesMinutes(-4.80225, false)},
    //             {name: 'Latitude', val: convertToDegreesMinutes(53.38133, true)},
    //             {name: 'Heading (°)', val: '184'},
    //             {name: 'Speed (kn)', val: '3.5'}
    //         ]
    //     },
    //     {
    //         title: 'Wave Data',
    //         data: [
    //             {name: 'Wave Length (m)', val: '12'},
    //             {name: 'Wave Height (m)', val: '5'},
    //             {name: 'Wave Speed (kn)', val: '5'},
    //             {name: 'Wave Direction (°)', val: '32'}
    //         ]
    //     },
    //     {
    //         title: 'Wind Data',
    //         data: [
    //             {name: 'Wind Speed (kn)', val: '12'},
    //             {name: 'Wind Direction (°)', val: '22'},
    //             {name: 'Gust Speed (kn)', val: '184'},
    //             {name: 'Gust Duration (s)', val: '3'}
    //         ]
    //     },
    // ]

    // return (
    //     <>
    //         <div className="flex flex-col">
    //             {data.map((el, idx) => <div key={idx}
    //                                         className="location-data-info location-data-planer px-[8px] py-[4px] rounded-[4px] w-[191px] bg-[#eff2f4] dark:bg-[#191919] dark:border-[#ffffff1a] border border-[rgba(0, 0, 0, 0.10)]">
    //                 <div
    //                     className="text-xs font-semibold border-b border-b-[rgba(0, 0, 0, 0.10)] pb-[5px] dark:border-[#ffffff1a] dark:text-white">
    //                     {el.title}
    //                 </div>
    //                 {el.data.map((it, idx) => <div key={idx} className="flex justify-between text-xs my-[4px]">
    //                     <span className=" dark:text-white">{it.name}</span>
    //                     <span className="text-[#00000080] dark:text-[#ffffff80]">{it.val}</span>
    //                 </div>)}
    //             </div>)}
    //         </div>
    //     </>
    // )

    return (
        <>
            <div className="flex flex-col h-96">
                <div className="location-data-info location-data-planer height-fast-fix px-[8px] py-[4px] rounded-[4px] w-[240px] bg-[#eff2f4] dark:bg-[#191919] dark:border-[#ffffff1a] border border-[rgba(0, 0, 0, 0.10)]">
                    <div
                        className="text-xs font-semibold border-b border-b-[rgba(0, 0, 0, 0.10)] pb-[5px] dark:border-[#ffffff1a] dark:text-white">
                        Last Point Data
                    </div>
                    {testData && testData.map((el,idx) => <div key={idx} className="flex justify-between text-xs my-[4px]">
                        <span className=" dark:text-white">{el[0]}</span>
                        <span className="text-[#00000080] dark:text-[#ffffff80]">{el[1]}</span>
                    </div>)}
                </div>
            </div>
        </>
    )
}
import { createSlice } from '@reduxjs/toolkit'
import {fetchAllMission, fetchCreateMission, fetchDeleteMission, fetchMissionById} from "../thunks/fleetThunks";

const initialState = {
    allMissions: null,
    currentMission: null,
    pagination: {}
}

const missionSlice = createSlice({
    name: 'mission',
    initialState,
    reducers: {
        setMissonStatus: (state, action) => {
            state.currentMission.status = action.payload;
        },
        addNewMission: (state, action) => {
            state.allMissions = [...(state.allMissions ? state.allMissions : []), action.payload];
        }
    },

    extraReducers(builder) {
        builder.addCase(fetchAllMission.fulfilled, (state, action) => {
            state.allMissions = action.payload.data;
            state.pagination = action.payload.pagination;
        });

        builder.addCase(fetchCreateMission.fulfilled, (state, action) => {
            if(action.payload) {
                state.allMissions = [...state.allMissions, action.payload];
            }
        });

        builder.addCase(fetchDeleteMission.fulfilled, (state, action) => {
            state.allMissions = state.allMissions.filter(m => m.id !== action.payload.id);
        });

        builder.addCase(fetchMissionById.fulfilled, (state, action) => {
            state.currentMission = action.payload 
        });

    }
})

export const {
    setMissonStatus, addNewMission
} = missionSlice.actions;

export const getAllMissions = (state) => state.mission.allMissions;
export const getCurrentMission = (state) => state.mission.currentMission;


export default missionSlice.reducer
import { Line } from "react-chartjs-2";
import React, { useRef, useState } from "react";
import zoomPlugin from 'chartjs-plugin-zoom';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const downsample = (data, factor) => data.filter((_, index) => index % factor === 0);

const averageByInterval = (data, interval) => {
    const averagedData = [];
    for (let i = 0; i < data.length; i += interval) {
        const segment = data.slice(i, i + interval);
        const avgValue = segment.reduce((sum, val) => sum + val, 0) / segment.length;
        averagedData.push(avgValue);
    }
    return averagedData;
};

const minMaxByInterval = (data, interval) => {
    const minMaxData = [];
    for (let i = 0; i < data.length; i += interval) {
        const segment = data.slice(i, i + interval);
        const min = Math.min(...segment);
        const max = Math.max(...segment);
        minMaxData.push({ min, max });
    }
    return minMaxData;
};


export const LineChartCustom = ({ data, title, setSliderVal }) => {
    const chartRef = useRef(null);


    const optimize = (data) => {
        const zoomLevel = data?.length || 0
        // console.log("zoomLevel:", zoomLevel);
        
        if (zoomLevel < 500) {
            // console.log("full data");
            return data
        } else if (1000) {
            // console.log("downsample");
            return downsample(data, 10)
        } else {
            // console.log("averageByInterval");
            return averageByInterval(data, 60)
        }
    };


    const dataSourceColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];

    const chartData = {
        title: data.title,
        labels: optimize(data.data.labels),
        datasets: data.data.datasets.map((dataset, index) => ({
            ...dataset,
            data: optimize(dataset.data),
            backgroundColor: dataSourceColors[index % dataSourceColors.length],
            borderColor: dataSourceColors[index % dataSourceColors.length],
            pointBackgroundColor: dataSourceColors[index % dataSourceColors.length],
            pointRadius: 3,
            borderWidth: 1,
            tension: 0,
        })),
    };

    const options = {
        ...data.options,
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, elements) => {
            const max = event.chart.chartArea.right
            setSliderVal(event.x * 100 / max)
            // if (elements.length > 0) {
            //     const datasetIndex = elements[0].datasetIndex;
            //     const index = elements[0].index;
            //     const value = data.datasets[datasetIndex].data[index];
            //     alert(`Clicked point: ${value}`);
            // }
        },
        plugins: {
            legend: {
                display: true,
                position: "right",
                align: "start",
                labels: {
                    boxWidth: 12,
                    boxHeight: 12,
                    padding: 20,
                },
            },
            // title: {
            //     display: true, // Set to true to show the title
            //     position: "top",
            //     text: 'Title', // Set the title text
            //     font: {
            //         size: 16, // Set title font size
            //     },
            //     padding: {
            //         top: 10, // Optional: padding above the title
            //         bottom: 20 // Optional: padding below the title
            //     },
            //     align: 'start' // Align title (start, center, end)
            // },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: 'y',
                },

                pan: {
                    enabled: true,
                    mode: 'y',
                },
            },
        },
    };

    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    return (
        <>
            <h4 className="font-medium text-center">{title}</h4>
            <div className="flex w-full mb-[24px] relative">
                <Line ref={chartRef} data={chartData} options={options} />
                <button onClick={handleResetZoom} style={{ position: 'absolute', right: 0, bottom: -16 }} className="flex items-center justify-center gap-1 font-semibold h-8 px-3 text-xs tracking-[4%] rounded border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917] w-max">
                    Reset zoom
                </button>
            </div>
        </>
    );
};

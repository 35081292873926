import { createRoot } from "react-dom/client";
import { toast } from "react-toastify";
import { PinWithNumber } from "../../components/parts/PinWithNumber";
import PopupContent from "./components/PopupContent";
import { isCurrentPoint } from "./utils";
import { validationConfig } from "./validationConfig";
import haversine from 'haversine-distance'


const L = window.L;


const METERS_TO_NAUTICAL_MILES = 1 / 1852;


const throttle = (func, delay) => {
    let lastCall = 0;
    let timeoutId;

    return function (...args) {
        const now = new Date().getTime();

        if (now - lastCall >= delay) {
            lastCall = now;
            func.apply(this, args);
        } else {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                lastCall = new Date().getTime();
                func.apply(this, args);
            }, delay - (now - lastCall));
        }
    };
};
function debounce(fn, delay) {
    let timeoutId;

    return function (...args) {
        const context = this;

        // Clear the previous timeout
        clearTimeout(timeoutId);

        // Set a new timeout
        timeoutId = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
}

export class Waypoint {
    _defaultTargetRadius = 25;
    _map = window.customWindy.map;
    _circle = null;
    _marker = null;
    _immediatelyUpdate = true;


    constructor(
        {
            lat, lng, lon, missionId, id, index, type, number, title = "Test", targetRadius, isReached = false,
            repeat_trajectory = 0, stationDays, stationHours, userId, createdAt = new Date().toISOString(),
            updatedAt = new Date().toISOString(), isHistorical = false, distanceToPrevious,
        },
        { onDelete = () => { }, onUpdate = () => { }, setWaypoints = () => { } }
    ) {
        if (!this._isValidLatLng(lat, lng || lon)) {
            return;
        }
        this._setWaypoints = setWaypoints;

        this.lat = lat;
        this.lng = lng || lon;

        this.title = title;
        this.missionId = missionId;
        this.type = type || null;

        this.isReached = isReached;

        if (index) {
            this.index = index;
        } else {
            this.calculateIndex()
        }
        this.id = id || this.generateId();
        this.setTargetRadius(targetRadius || this._defaultTargetRadius);
        this.eta = null;
        this.repeat_trajectory = repeat_trajectory;
        this.stationDays = stationDays;
        this.stationHours = stationHours;
        this.userId = userId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.isHistorical = isHistorical;
        this.distanceToPrevious = distanceToPrevious

        if (!window.markers.some(mark => isCurrentPoint(mark._latlng, this))) {
            this.init();
        }
    }
    get _latlng() {
        return { lat: this.lat, lng: this.lng }
    }
    generateId() {
        return `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    }
    calculateIndex() {
        const index = window.wayPoints.indexOf(this)
        this.setIndex(index > -1 ? index : window.wayPoints.length)
    }
    setIndex(value) {
        if (value >= 0) {
            this.index = value;
            this.updateMarkerIcon()
        } else {
            toast.error("index must be > 0")
        }
    }
    calculateDistance() {
        const previousWaypoint = window.wayPoints[this.index - 1];
        if (!previousWaypoint) {
            this.distanceToPrevious = 0;
        } else {
            const distanceInMeters = haversine(
                { latitude: this.lat, longitude: this.lng },
                { latitude: previousWaypoint.lat, longitude: previousWaypoint.lng }
            );
            this.distanceToPrevious = (distanceInMeters * METERS_TO_NAUTICAL_MILES).toFixed(2);
        }
        if (this._root) {
            this.renderPopupContent(this._root);
        }
        
    }
    updateTargetRadius(value) {
        this.setTargetRadius(value);
        this._circle?.setRadius(this.targetRadius);
        this.updateState();
    }
    setTargetRadius(value) {
        const { min, max } = validationConfig.radius;
        if (!isNaN(parseFloat(value)) && value >= min && value <= max) {
            this.targetRadius = parseInt(value);
        } else {
            this.targetRadius = this._defaultTargetRadius;
            toast.error(`Waypoint ${this.index + 1}:Target radius must be between ${min} and ${max}. Current value is ${value}`);
        }
    }
    setLatLng({ lat, lng }, updateOthersState = true) {
        if (this._isValidLatLng(lat, lng)) {
            this.lat = lat;
            this.lng = lng;
            this._circle?.setLatLng({ lat, lng });
            this.updateState();
            if (updateOthersState) {
                this.updateOthersState()
            }
        }
    }
    _isValidLatLng(lat, lng) {
        const validLat = !isNaN(parseFloat(lat)) && lat >= -90 && lat <= 90;
        const validLng = !isNaN(parseFloat(lng)) && lng >= -180 && lng <= 180;

        if (!validLat) toast.error(`Waypoint ${this.index + 1}: Latitude must be between -90 and 90. Current value is ${lat}`);
        if (!validLng) toast.error(`Waypoint ${this.index + 1}: Longitude must be between -180 and 180.Current value is ${lng}`);

        return validLat && validLng;
    }
    drawCircle() {
        const color = this.isHistorical ? "#343C44" : "#3477B5";
        this._circle = L.circle([this.lat, this.lng], {
            color, fillColor: color, fillOpacity: 0.3, radius: this.targetRadius
        }).addTo(this._map);
    }
    renderPopupContent(root) {
        root.render(
            <PopupContent
                data={this}
                showLoop={this.index === window.wayPoints?.length - 1}
                isHistorical={this.isReached}
                currentMission={this._mission}
            />

        );
    }
    renderCoordPopupContent(root) {
        root.render(
            <div>
                {!!this.distanceToPrevious && <p className="!mt-[4px] text-[12px] font-semibold text-[#0B0B0B80]">Distance: {this.distanceToPrevious} nm</p>}
                <p className="!mt-[8px] text-[12px] font-semibold text-[#0B0B0B80]">lat: {this.lat}</p>
                <p className="!mt-[4px] text-[12px] font-semibold text-[#0B0B0B80]">lng: {this.lng}</p>
            </div>
        );
    }
    async createPathIcon() {
        const html = document.createElement('div');
        const root = createRoot(html);

        return new Promise(resolve => {
            root.render(
                <PinWithNumber
                    number={this.index + 1}
                    isHistorical={this.isHistorical || this.isReached}
                    type={this.type}
                    onRendered={() => resolve(L.divIcon({
                        iconSize: [48, 48],
                        iconAnchor: [24, 48],
                        html: html.innerHTML
                    }))}
                />
            );
        });
    }
    async updateMarkerIcon() {
        if (!this._marker) return
        const newIcon = await this.createPathIcon();
        this._marker.setIcon(newIcon);

    }
    async createMarker() {
        this._popup = document.createElement('div');
        this._coordPopup = document.createElement('div');
        this._root = createRoot(this._popup);
        this._coordRoot = createRoot(this._coordPopup);

        // const throttledHandleDrag = throttle(e => this._handleDrag(e), 0);
        const marker = L.marker([this.lat, this.lng], {
            icon: await this.createPathIcon(),
        }).addTo(this._map)
            .on('click', e => this._handleMarkerClick(e))
            .on("drag", e => this._handleDrag(e))
            .on('dragend', (e) => this._handleDragEnd(e));
        this.renderPopupContent(this._root);
        this.renderCoordPopupContent(this._coordRoot);
        this._marker = marker;
        window.markers.push(marker);
    }
    _handleMarkerClick(e) {
        const marker = e.target;

        this.renderPopupContent(this._root)
        const newPopup = L.popup({ offset: [0, -48] }).setContent("Loading...")
        setTimeout(() => {
            newPopup
                .setContent(this._popup.innerHTML);
        }, 100)
        window.isWaypointPopupOpen = true;
        if (marker.getPopup()) {
            marker.closePopup();
            marker.unbindPopup();
        }

        marker.bindPopup(newPopup).openPopup();
        this._setupPopupListeners();
        marker.getPopup().on('remove', () => {
            setTimeout(() => window.isWaypointPopupOpen = false, 100);
            marker.unbindPopup();
        });

    }
    _setupPopupListeners() {
        setTimeout(() => {
            this._addInputListeners([
                { selector: '.repeat-trajectory-input', handler: this._onInputChange.bind(this, 'repeat_trajectory') },
                { selector: '.station-days-input', handler: this._onInputChange.bind(this, 'stationDays') },
                { selector: '.station-hours-input', handler: this._onInputChange.bind(this, 'stationHours') },
                { selector: '.target-radius-input', handler: this._onTargetRadiusChange.bind(this) },
                { selector: '.lat-input', handler: e => this.setLatLng({ lat: +e.target.value, lng: this.lng }) },
                { selector: '.lng-input', handler: e => this.setLatLng({ lat: this.lat, lng: +e.target.value }) },
            ]);
            this._addClickListeners([
                { selector: '.delete-waypoint-btn', handler: e => this.destroy() },
            ]);
        }, 150);
    }
    _addInputListeners(listeners) {
        listeners.forEach(({ selector, handler }) => {
            const input = document.querySelector(`.leaflet-pane.leaflet-popup-pane ${selector}`);
            input?.addEventListener('change', handler);
        });
    }
    _addClickListeners(listeners) {
        listeners.forEach(({ selector, handler }) => {
            const button = document.querySelector(`.leaflet-pane.leaflet-popup-pane ${selector}`);
            button?.addEventListener('click', handler);
        });
    }
    determineType(stationDays, stationHours) {
        if (stationDays > 0 || stationHours > 0) {
            return 'station_keeping';
        }
        this.updateMarkerIcon()
        return null;
    }
    _onInputChange(prop, e) {
        this[prop] = +e.target.value;
        this.type = this.determineType(this.stationDays, this.stationHours);
        this.updateState();
    }
    _onTargetRadiusChange(e) {
        this.updateTargetRadius(e.target.value);
    }
    _handleDrag(e) {
        this.renderCoordPopupContent(this._coordRoot);

        const latlng = e.target.getLatLng();
        this.setLatLng(latlng, false);

        const newPopup = L.popup({ offset: [0, -48] }).setContent(this._coordPopup.innerHTML);
        e.target.bindPopup(newPopup).openPopup();
    }
    _handleDragEnd(e) {
        // const latlng = e.target.getLatLng();
        // this.setLatLng(latlng);
        this.updateOthersState()
        if (this._marker.getPopup()) {
            this._marker.closePopup();
            this._marker.unbindPopup();
        }
    }
    remove() {
        this._marker?.remove();
        this._circle?.remove();
        const markerIndex = window.markers.indexOf(this._marker);
        if (markerIndex !== -1) window.markers.splice(markerIndex, 1);
    }
    destroy() {
        this.removeFromState();
        // window.wayPoints = []
        // window.markers = []
    }
    init() {
        this.updateState();
        // this.updateOthersState();
        this.drawCircle();
        this.createMarker();
    }
    removeFromState() {
        this.remove()
        this._setWaypoints(prev => {
            const index = prev.indexOf(this);
            const newWaypoints = [...prev];
            if (index > -1) {
                newWaypoints.splice(index, 1);
            }
            this.memo(newWaypoints)
            this.calculateDistance()
            this.updateOthersState()
            return newWaypoints;
        });
    }
    updateState() {
        this._setWaypoints(prev => {
            const index = prev.indexOf(this);
            const newWaypoints = [...prev];
            if (index > -1) {
                newWaypoints[index] = this;
            } else {
                newWaypoints.push(this);
            }
            this.memo(newWaypoints)
            this.calculateDistance()
            return newWaypoints;
        });
    }
    getData() {
        return {
            isReached: this.isReached,
            id: this.id,
            lat: this.lat,
            lon: this.lng,
            index: this.index,
            eta: this.eta,
            repeat_trajectory: this.repeat_trajectory,
            title: this.title,
            targetRadius: this.targetRadius,
            type: this.type,
            stationDays: this.stationDays || null,
            stationHours: this.stationHours || null,
            userId: this.userId,
            missionId: this.missionId,
        };
    }
    memo(data) {
        window.localStorage.setItem("draft", JSON.stringify({
            id: this.missionId,
            waypoints: data.map(point => point.getData())
        }))
        window.wayPoints = data
    }
    updateOthersState() {
        window.wayPoints.forEach((point, index) => {
            if (point.index === this.index) return;
            point.updateState()
            point.setIndex(index)
        })
    }
}
export const convertLatLngToApiFormat = (obj) => {
    const convertedObj = { ...obj }
    convertedObj.lng = obj.lon || obj.lng
    return convertedObj
}

export const isCurrentPoint = (latlng1, latlng2) => {
    const { lat: lat1, lng: lng1 } = latlng1
    const { lat: lat2, lng: lng2 } = latlng2
    const tolerance = 1e-4;

    return Math.abs(+lat1 - +lat2) < tolerance && Math.abs(+lng1 - +lng2) < tolerance
}
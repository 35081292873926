import React, { useEffect, useState } from 'react'
import api from '../api'
import { getMediaUrl } from '../utils'

import { ReactComponent as DeleteIcon } from "../assets/img/icons/delete.svg"
import { toast } from 'react-toastify'

const MediaUploader = () => {
    const [selectedFiles, setSelectedFiles] = useState([])
    const [uploadedMedia, setUploadedMedia] = useState([])

    const uploadMedia = async (files) => {
        const formData = new FormData()
        Array.from(files).forEach((file) => {
            formData.append('media', file)
        })
        const res = await api.users.createUserMedia(formData);

        if (res.success) {
            setUploadedMedia([...uploadedMedia, res.data])
        }
    }

    const getMedia = async () => {
        const res = await api.users.getUserMedia()
        if (res.success) {
            setUploadedMedia(res.data)
        }

    }

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files)
        const validFiles = files.filter(file => file.size <= 5 * 1024 * 1024) // limit to 5 MB
        if (validFiles.length !== files.length) {
            toast.error('File must be 5 MB or less')
        }
        setSelectedFiles(validFiles)
    }

    const handleUpload = () => {
        if (selectedFiles.length) {
            uploadMedia(selectedFiles)
            setSelectedFiles([])
        }
    }

    const handleDelete = async (id) => {
        const res = await api.users.deleteUserMedia(id)
        if (res.success) {
            setUploadedMedia(prev => prev.filter((media) => media.id !== id))
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    return (
        <div className="p-4 w-full">
            <div className='flex flex-col w-full items-center gap-4 mb-4'>
                {
                    selectedFiles.length ? (
                        <div className="grid grid-cols-1 gap-2">
                            {selectedFiles.map((media, index) => (
                                <div key={index} className="w-full h-64 bg-gray-100 rounded overflow-hidden relative">
                                    <img
                                        src={getMediaUrl(media)}
                                        alt="Uploaded media"
                                        className="w-full h-full object-cover"
                                    />
                                    <button
                                        className="absolute top-0 right-0 group flex items-center justify-center py-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917] w-fit px-3"
                                        onClick={() => setSelectedFiles([])}
                                    >
                                        <DeleteIcon />
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <label
                            className="cursor-pointer group transition-all flex items-center justify-center py-[24px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-black border-[#0B0B0B40] bg-white hover:bg-[#c5201d2f] active:bg-[#9F1917] w-full px-3"
                        >
                            <input
                                className="hidden"
                                type="file"
                                onChange={handleFileChange}
                                accept="image/png"
                            />
                            Secect Media
                        </label>
                    )
                }

                <button
                    onClick={handleUpload}
                    disabled={!selectedFiles.length}
                    className={`group cursor-pointer flex items-center justify-center py-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917] w-full px-3", {
                        ${!selectedFiles.length ? "opacity-30 pointer-events-none" : ""}`}
                >
                    Upload
                </button>
            </div>

            <div className="grid grid-cols-3 gap-2">
                {uploadedMedia.map((media, index) => (
                    <div key={index} className="w-full h-32 relative bg-gray-100 rounded overflow-hidden">
                        {
                            media.fileType && (
                                <img
                                    src={getMediaUrl(media.fileName)}
                                    alt="Uploaded media"
                                    className="w-full h-full object-cover"
                                />

                            )
                        }
                        <button
                            className="absolute top-0 right-0 group flex items-center justify-center py-[4px] gap-[4px] font-semibold h-[32px] rounded-[4px] text-[12px] tracking-[4%] border text-white border-[#0B0B0B40] bg-[#E42320] hover:bg-[#C51F1D] active:bg-[#9F1917] w-fit px-3"
                            onClick={() => handleDelete(media.id)}
                        >
                            <DeleteIcon />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MediaUploader
